import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const TripRedirect = () => {
  const { id } = useParams();
  console.log(id);

  // DOES NOT WORK IN TELEGRAM TEST ENVIRONMENT!!!
  const redirectLink = `https://t.me/${process.env.REACT_APP_TELEGRAMBOTNAME}/app?startapp=tripId-${id}`;

  useEffect(() => {
    window.location.href = redirectLink;
  }, [id, redirectLink]);

  return (
    <div>
      <p>You are being redirected. If it doesn't happen, <a href={redirectLink}>click here</a> to proceed.</p>
    </div>
  );
};

export default TripRedirect;
