import React from 'react';

const NotAuthorizedPage = () => {
  return (
    <div className="container-fluid">
      <div>
        <br />
        <h2>Hey, traveler!</h2>
        <p>Whoflies app is designed to work in Telegram only. Click on the button to start the app from Telegram.</p>
        <a href="https://t.me/whoflies_bot" className="btn btn-primary">Start Whoflies</a>
        <p></p>
        <p>If you are launching the app from Telegram and still see this page, please do not hesitate <a href="https://t.me/ramified">to let us know.</a></p>
      </div>
      <br /> <br />
      <div>
        <h2>Привет, путешественник!</h2>
        <p>Приложение Whoflies предназначено для работы только в Telegram. Нажмите на кнопку ниже, чтобы запустить приложение.</p>
        <a href="https://t.me/whoflies_bot" className="btn btn-primary">Запустить Whoflies</a>
        <p></p>
        <p>Если вы запускаете приложение из Telegram и по-прежнему видите эту страницу, пожалуйста, <a href="https://t.me/ramified">свяжитесь с нами.</a></p>
      </div>
    </div>
  );
};

export default NotAuthorizedPage;
