//useAuthenticate.js

import { useEffect, useState } from "react";
import { fetchDataForAuthentication } from "../services/auth.service";
import { checkAdmin } from "../services/auth.service";

export const useAuthenticate = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [userId, setUserId] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseData = await fetchDataForAuthentication();

        if (
          responseData &&
          responseData.status === "User authorized" &&
          responseData.user_id !== null &&
          responseData.telegramId !== null
        ) {
          setIsAuthenticated(true);

          // Store user data
          sessionStorage.setItem("user_id", responseData.user_id);
          sessionStorage.setItem("telegramId", responseData.telegramId);

          await checkAdminStatus();

          // Set up Google Analytics
          // initGA(responseData.user_id);
          const userId = responseData.user_id
          setUserId(userId)
        }
      } catch (error) {
        setError(error);
      }
    };
    fetchData();
  }, []);

  const checkAdminStatus = async () => {
    try {
      const { isAdmin } = await checkAdmin();
      if (isAdmin) {
        setIsAdmin(isAdmin);
        sessionStorage.setItem("isAdmin", isAdmin);
        console.log("I am the god of war!");
      } else {
        return;
      }
    } catch (error) {
      setError(error);
    }
  };

  return { isAuthenticated, isAdmin, userId };
};
