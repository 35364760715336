// share-post.component.js
import React from "react";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import ShareIcon from "@mui/icons-material/Share";

const SharePost = ({
  type,
  cityFrom,
  countryFrom,
  cityTo,
  countryTo,
  description,
  date,
  id,
}) => {
  const { t } = useTranslation();
  const userLanguage = sessionStorage.getItem("user_language");

  const buildShareLink = () => {
    const baseUrl = "https://t.me/whoflies_bot/app";
    const queryParams = new URLSearchParams({
      startapp: `${type === "trip" ? "tripId" : "orderId"}-${id}`,
    });

    return `${baseUrl}?${queryParams}`;
  };

  const formatDateShortenRu = (date) => {
    const options = {
      day: "numeric",
      month: "long",
      year: "numeric",
    };
    return new Date(date).toLocaleDateString("ru-RU", options);
  };

  const formatDateShortenEn = (date) => {
    const options = {
      month: "long",
      day: "numeric",
    };

    const formattedDate = new Date(date);
    return formattedDate.toLocaleDateString("en-US", options);
  };

  const handleShare = () => {
    const shareLink = buildShareLink();
    const formattedDateStringRu = formatDateShortenRu(date);
    const formattedDateStringEn = formatDateShortenEn(date);

    let textKey;
    if (type === "trip") {
      textKey = "sharing.trip.textMessage";
    } else if (type === "order") {
      textKey = "sharing.order.textMessage";
    }

    const textRu = t(textKey, {
      cityFrom,
      countryFrom,
      cityTo,
      countryTo,
      formattedDate: formattedDateStringRu,
    });
    const textEn = t(textKey, {
      cityFrom,
      countryFrom,
      cityTo,
      countryTo,
      formattedDate: formattedDateStringEn,
    });
    if (window.confirm(t("postModal.button.shareButtonConfirmation"))) {
      share(
        shareLink,
        type,
        cityFrom,
        countryFrom,
        cityTo,
        countryTo,
        description,
        formattedDateStringRu,
        formattedDateStringEn,
        textRu,
        textEn
      );
    }
  };

  const share = (
    shareLink,
    type,
    cityFrom,
    countryFrom,
    cityTo,
    countryTo,
    description,
    formattedDateRu,
    formattedDateEn,
    textRu,
    textEn
  ) => {
    const encodedLink = encodeURIComponent(shareLink);
    const encodedTextRu = encodeURIComponent(textRu);
    const encodedTextEn = encodeURIComponent(textEn);
    const telegramShareLinkRu = `https://t.me/share/url?url=${encodedLink}&text=${encodedTextRu}`;
    const telegramShareLinkEn = `https://t.me/share/url?url=${encodedLink}&text=${encodedTextEn}`;

    window.open(
      userLanguage === "ru" ? telegramShareLinkRu : telegramShareLinkEn
    );
  };

  return (
    <Button
      variant="text"
      startIcon={<ShareIcon />}
      onClick={handleShare}
      sx={{
        marginBottom: "10px",
      }}
    >
      {type === "trip"
        ? t("sharing.trip.sharebutton")
        : t("sharing.order.sharebutton")}
    </Button>
  );
};

export default SharePost;
