//FormValidator.js
import dayjs from "dayjs";
import geoNameService from "../../services/geo-name.service";

export const descriptionValidator = (value) => {
  if (value.length < 10) return "error"
  return false;
};

export const dateDepartureValidator = (value) => {
  const today = dayjs().startOf("day");
  const selectedDate = dayjs(value).startOf("day");

  if (selectedDate.isBefore(today)) return "error";

  return false;
};

export const dateArrivalValidator = (value) => {
  const today = dayjs().startOf("day");
  const selectedDate = dayjs(value).startOf("day");

  if (selectedDate.isBefore(today)) return "error";

  return false;
};

export const dateByValidator = (value) => {
  const today = dayjs().startOf("day");
  const selectedDate = dayjs(value).startOf("day");

  if (selectedDate.isBefore(today)) return "error";

  return false;
};

export const locationValidator = (value) => {
  return false;
};


export const dateByValidatorNew = (value) => {
  const today = dayjs().startOf("day");
  const selectedDate = dayjs(value).startOf("day");
  console.log (today, selectedDate)
  
  if (selectedDate.isBefore(today)) return true
}

export const postValidator = (postDetails) => {
  const currentUserId = parseInt(sessionStorage.getItem('user_id'));
  const errors = {};

  if (!postDetails.state) {
    errors.state = "State of the post must be provided"
  }
  
  if (!postDetails.authorId || currentUserId !== postDetails.authorId) {
    errors.authorId = "You must be an author of the post"
  }

  if (!postDetails.description || postDetails.description.length < 10) {
    errors.description = "Too short description" 
  }

  // create separate function for checking locations
  if (!postDetails.countryFromId || !postDetails.cityFromId || !postDetails.countryToId || !postDetails.cityToId) {
    errors.location = "Please check if both locations are provided"
  }

  if (postDetails.type === "order" && dateByValidatorNew(postDetails.dateBy)) {
    errors.dateBy = "Date By must be later than today"
  }

  return errors;
};
