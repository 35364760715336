//trip.service.js

import axiosInstance from "../axios-common";

class TripDataService {

  // GET ALL TRIPS SORTED BY PUBLISHED DATE
  getAll() {
    return axiosInstance.get("/trips");
  }

  // USED FOR SEARCH ON MAIN LIST, RETURNED SORTED BY PUBLISHED DATE
  getTripsByPlace(countryFromId, cityFromId, countryToId, cityToId) {
    return axiosInstance.get(`/trips/place`, {
      params: {
        countryFromId,
        cityFromId,
        countryToId,
        cityToId,
      },
    });
  }

  // MY TRIPS SORTED BY CREATED DATE (AUTHORID FROM SESSION STORAGE)
  getTripsByAuthorId(authorId) {
    return axiosInstance.get(`/trips/byauthor`, {
      params: {
        authorId,
      },
    });
  }

  // GET SPECIFIC TRIP BY ITS ID
  get(id) {
    return axiosInstance.get(`/trips/${id}`);
  }

  // CREATE NEW TRIP
  createTrip(data) {
    return axiosInstance.post("/trips", data);
  }

  // UPDATE SPECIFIC TRIP BY ITS ID
  update(id, data) {
    return axiosInstance.put(`/trips/${id}`, data);
  }

  // DELETE (CHANGE STATE) SPECIFIC TRIP BY ITS ID
  delete(id) {
    return axiosInstance.put(`/trips/${id}/delete`);
  }
}

const TripService = new TripDataService();

export default TripService;