//themeWrapper.js

import React, { useState, useEffect } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import WebApp from "@twa-dev/sdk";

const DEFAULT_THEME_PARAMS = {
  accent_text_color: "#168dcd",
  bg_color: "#ffffff",
  button_color: "#40a7e3",
  button_text_color: "#ffffff",
  destructive_text_color: "#d14e4e",
  header_bg_color: "#ffffff",
  hint_color: "#999999",
  link_color: "#168dcd",
  secondary_bg_color: "#f1f1f1",
  section_bg_color: "#ffffff",
  section_header_text_color: "#168dcd",
  subtitle_text_color: "#999999",
  text_color: "#000000",
};

const createMuiTheme = (themeParams) => {
  const {
    accent_text_color,
    bg_color,
    button_color,
    button_text_color,
    destructive_text_color,
    header_bg_color,
    hint_color,
    link_color,
    secondary_bg_color,
    section_bg_color,
    section_header_text_color,
    subtitle_text_color,
    text_color,
  } = themeParams;

  return createTheme({
    palette: {
      primary: {
        main: link_color, //buttons, links, active state inputs, active elements (active day in calendar)
      },
      secondary: {
        main: "#ff0000", // ?????
      },
      error: {
        main: destructive_text_color, // input error borders and label
      },
      background: {
        default: bg_color, // main bg (fixed with cssbaseline in index.js)
        paper: secondary_bg_color, // bg for listing, content, etc.
      },
      text: {
        primary: text_color, // all non-element specific texts
        secondary: hint_color, // subtitles, lables for fields (not values)
      },
      // warning: {
      //   main: header_bg_color, // use for alerts in warning state (e.g. user profile)
      // },
      tabTitle: {
        fontSize: "1.1em",
        activeColor: button_text_color,
        inactiveColor: subtitle_text_color,
      },
    },
    typography: {
      h6: {
        fontSize: 16,
        fontWeight: 600,
        color: accent_text_color,
        paddingBottom: "10px",
      },
    },
    components: {
      MuiIconButton: {
        styleOverrides: {
          root: {
            color: hint_color,
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            color: hint_color,
          },
        },
      },
      // MuiBackdrop: { // need first to dynamically convert color to rgba 
      //   styleOverrides: {
      //     root: {
      //       backgroundColor: `rgba(var(--secondary-bg-color), 0.9)`,
      //     }
      //   }
      // }
    },
  });
};

const isTelegramPlatform = (platform) => {
  const telegramPlatforms = [
    "android",
    "android_x",
    "ios",
    "macos",
    "tdesktop",
    "weba",
    "webk",
  ];
  return telegramPlatforms.includes(platform);
};

const ThemeWrapper = ({ children }) => {
  const [dynamicTheme, setDynamicTheme] = useState(() => {
    const platform = WebApp.platform;
    const themeParams = WebApp.themeParams || DEFAULT_THEME_PARAMS;

    if (platform && isTelegramPlatform(platform)) {
      return createMuiTheme(themeParams);
    } else {
      // Use default theme for non-Telegram platforms
      return createMuiTheme(DEFAULT_THEME_PARAMS);
    }
  });

  useEffect(() => {
    const handleThemeChanged = () => {
      const themeParams = WebApp.themeParams || DEFAULT_THEME_PARAMS;
      setDynamicTheme(createMuiTheme(themeParams));
    };
    window.Telegram.WebApp.onEvent("themeChanged", handleThemeChanged);

    return () => {
      window.Telegram.WebApp.offEvent("themeChanged", handleThemeChanged);
    };
  }, []);

  return <ThemeProvider theme={dynamicTheme}>{children}</ThemeProvider>;
};

export default ThemeWrapper;
