// styles.js
import styled from "@emotion/styled";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";

//simple box with 10px margin
export const WrapBox = styled(Box)`
  margin: 10px;
  display: "flex"; 
  flexDirection: "column";
`;

// without margin, to be included within WrapBox
export const StyledBoxNew = styled(Box)`
  background-color: ${(props) => useTheme().palette.background.paper};
  padding: 15px;
  border-radius: 10px;
`;

// with secondary background
export const StyledBox = styled(Box)`
  background-color: ${(props) => useTheme().palette.background.paper};
  margin: 10px;
  padding: 15px;
  border-radius: 10px;
`;
