import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { StyledBox } from "../styles";
import { Box, Stack, Typography, Button } from "@mui/material";
import TelegramIcon from "@mui/icons-material/Telegram";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const Support = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleLeaveApp = () => {
    setOpen(false);
    window.location.href = "https://t.me/ramified";
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Stack direction="column" alignItems="center" spacing={1}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src="./images/supportPage.png"
            alt="Need Support?"
            style={{ marginTop: "10px", width: "30%" }}
          />
        </Box>
      </Stack>
      <StyledBox>
        <Typography variant="h6">{t("support.contactus.heading")}</Typography>
        <Typography variant="body1">
          {t("support.contactus.description")}
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "25px 0px 15px 0px",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            startIcon={<TelegramIcon />}
            onClick={handleOpen}
          >
            {t("support.contactus.button")}
          </Button>
        </Box>
      </StyledBox>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{t("support.dialog.title")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("support.dialog.content")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("support.dialog.cancel")}</Button>
          <Button onClick={handleLeaveApp}>{t("support.dialog.confirm")}</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Support;
