import * as React from "react";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AvatarUrlComponent from "./avatar";
import AccountCircle from "@mui/icons-material/AccountCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import HelpIcon from "@mui/icons-material/Help";
import InfoIcon from "@mui/icons-material/Info";
import MenuItem from "@mui/material/MenuItem";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import Menu from "@mui/material/Menu";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function ButtonAppBar() {
  const { t } = useTranslation();
  const currentUserId = parseInt(sessionStorage.getItem("user_id"));
  const isAdmin = sessionStorage.getItem("isAdmin");
  console.log("is admin", isAdmin)

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const data = [
    {
      icon: <AccountCircle />,
      label: t("userMenu.myProfile"),
      url: `/user/${currentUserId}`,
    },
    {
      icon: <AddCircleIcon />,
      label: t("userMenu.addNewPost"),
      url: `/createpost`,
    },
    {
      icon: <HelpIcon />,
      label: t("userMenu.support"),
      url: `/support`,
    },
    {
      icon: <InfoIcon />,
      label: t("userMenu.aboutUs"),
      url: `/aboutus`,
    },
  ];

  if (isAdmin === "true") {
    data.push({
      icon: <AdminPanelSettingsIcon />,
      label: "Dashboard",
      url: `/admin`,
    });
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar>
          <Box sx={{ flexGrow: 1 }}>
            <Link to="/" replace component="a">
              <img
                src="./logo.png"
                alt="Logo"
                style={{ width: 40, height: 40 }}
              />
            </Link>
          </Box>
          <IconButton
            size="large"
            edge="end"
            aria-label="menu"
            aria-haspopup="true"
            onClick={handleMenu}
            sx={{ marginLeft: "auto" }}
          >
            <AvatarUrlComponent
              telegramId={sessionStorage.getItem("telegramId")}
              className="navbar-avatar"
            />
            <KeyboardArrowDownIcon />
          </IconButton>

          <Menu
            id="menu-appbar"
            anchorReference="anchorEl"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {data.map((item) => (
              <MenuItem
                key={item.label}
                component={Link}
                to={item.url}
                onClick={handleClose}
                style={{ textDecoration: "none" }}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                {item.label}
              </MenuItem>
            ))}
          </Menu>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
