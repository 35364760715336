// ./telegram/avatar-url.component.js

import React, { useState, useEffect } from 'react'
import TelegramProxyService from '../../services/telegramproxy.service';

const AvatarUrlComponent = ({ telegramId, className }) => {
  const [avatarUrl, setAvatarUrl] = useState('')
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoaded = () => {
    setIsLoading(false);
  };

  const handleImageError = () => {
    setIsLoading(false);
    setAvatarUrl('./defaultAvatar.jpg');
  };

  const validateURL = (url) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
      img.src = url;
    });
  };

  useEffect(() => {
    const fetchAvatarUrl = async () => {
      if (telegramId) {
        try {
          const response = await TelegramProxyService.getImageById(telegramId);
          const url = response.data.imageUrl;
          const isValid = await validateURL(url);

          if (isValid) {
            setAvatarUrl(url);
          } else {
            setAvatarUrl('./defaultAvatar.jpg');
          }
        } catch (error) {
          console.error('Error fetching avatar URL:', error)
          setAvatarUrl('./defaultAvatar.jpg')
        }
      } else {
        setAvatarUrl('./defaultAvatar.jpg')
      }
    }
    fetchAvatarUrl()
  }, [telegramId])
  
  return (
    <img
      src={avatarUrl}
      alt={`Avatar for ${telegramId}`}
      className={`avatar-image ${className}`}
      onLoad={handleImageLoaded}
      onError={handleImageError}
    />
  )
}

export default AvatarUrlComponent
