import axiosInstance from '../axios-common'

class StatDataService {

recordInteraction(interactionData) {
    return axiosInstance.post('/stats/interactions', interactionData);
  }

}

const StatService = new StatDataService();

export default StatService;