//order.service.js

import axiosInstance from "../axios-common";

class OrderDataService {

  // GET ALL ORDERS SORTED BY PUBLISHED DATE
  getAll() {
    return axiosInstance.get("/orders");
  }

  // USED FOR SEARCH ON MAIN LIST, RETURNED SORTED BY PUBLISHED DATE
  getOrdersByPlace(countryFromId, cityFromId, countryToId, cityToId) {
    return axiosInstance.get(`/orders/place`, {
      params: {
        countryFromId,
        cityFromId,
        countryToId,
        cityToId
      },
    });
  }

  // MY ORDERS SORTED BY CREATED DATE (AUTHORID FROM SESSION STORAGE)
  getOrdersByAuthorId (authorId) {
    return axiosInstance.get(`/orders/byauthor`, {
      params: {
        authorId
      }
    })
  }

  // GET SPECIFIC ORDER BY ITS ID
  get(id) {
    return axiosInstance.get(`/orders/${id}`);
  }

  // CREATE NEW ORDER
  createOrder(data) {
    return axiosInstance.post("/orders", data);
  }

  // UPDATE SPECIFIC ORDER BY ITS ID
  update(id, data) {
    return axiosInstance.put(`/orders/${id}`, data);
  }

  // DELETE (CHANGE STATE) SPECIFIC ORDER BY ITS ID
  delete(id) {
    return axiosInstance.put(`/orders/${id}/delete`);
  }
}

const OrderService = new OrderDataService();

export default OrderService;