
import { useEffect, useState } from "react";
import WebApp from "@twa-dev/sdk";
import i18n from "../i18n";
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/ru';

export const useDetectUserLanguage = () => {
  const [userLanguage, setUserLanguage] = useState("en");

  useEffect(() => {
    const detectUserLanguage = () => {

      const decodedInitDataString = decodeURIComponent(WebApp.initData);
      try {
        const initData = JSON.parse(
          decodedInitDataString.substring(
            decodedInitDataString.indexOf("{"),
            decodedInitDataString.lastIndexOf("}") + 1
          )
        );
        const userLanguage = initData.language_code || "en";
        setUserLanguage(userLanguage);
        sessionStorage.setItem("user_language", userLanguage);
        i18n.changeLanguage(userLanguage);
        dayjs.locale(i18n.resolvedLanguage)
      } catch (error) {
        console.error("Error parsing initData for language detection:", error);
      }
    };
    detectUserLanguage();
  }, []);
  return { userLanguage };
};
