//axios-common.js

import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers: {
    "Content-type": "application/json"
  }
});

export default axiosInstance;