// SearchModal
import React, { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import GeoNameService from "../services/geo-name.service";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

const SearchModal = ({ onClose, onSearch, open }) => {
  const { t } = useTranslation();
  const [fromLocation, setFromLocation] = useState(null);
  const [toLocation, setToLocation] = useState(null);
  const [fromLocationQuery, setFromLocationQuery] = useState("");
  const [toLocationQuery, setToLocationQuery] = useState("");
  const [fromLocationSuggestions, setFromLocationSuggestions] = useState([]);
  const [toLocationSuggestions, setToLocationSuggestions] = useState([]);
  const userLanguage = sessionStorage.getItem("user_language");

  // waiting for input to trigger fetching
  useEffect(() => {
    const fetchFromSuggestions = async () =>
      fetchSuggestions(fromLocationQuery, "fromLocation");
    const fetchToSuggestions = async () =>
      fetchSuggestions(toLocationQuery, "toLocation");

    Promise.all([fetchFromSuggestions(), fetchToSuggestions()]).then(
      ([fromSuggestions, toSuggestions]) => {
        setFromLocationSuggestions(fromSuggestions);
        setToLocationSuggestions(toSuggestions);
      }
    );
  }, [fromLocationQuery, toLocationQuery]);

  // fetch and return array of places
  const fetchSuggestions = (query, field) => {
    GeoNameService.getLocationSuggestions(query)
      .then((response) => {
        const responseData = response.data;

        if (field === "fromLocation") {
          setFromLocationSuggestions(responseData);
        } else if (field === "toLocation") {
          setToLocationSuggestions(responseData);
        }
      })
      .catch((error) => {
        console.error("Error fetching suggestions:", error);
      });
  };

  // return to parent component values through onSearch prop
  const passProps = async () => {
    if (!fromLocation || !toLocation) {
      console.error("Both locations must be selected");
      return;
    }

    const countryFromId = fromLocation.country_id;
    const cityFromId = fromLocation.city_id;
    const countryToId = toLocation.country_id;
    const cityToId = toLocation.city_id;

    const [cityFromName, cityToName] = await Promise.all([
      GeoNameService.getCityById(cityFromId, userLanguage),
      GeoNameService.getCityById(cityToId, userLanguage),
    ]).then((responses) => [
      responses[0].data[
        userLanguage === "ru" ? "city_name_ru" : "city_name_en"
      ],
      responses[1].data[
        userLanguage === "ru" ? "city_name_ru" : "city_name_en"
      ],
    ]);

    console.log(cityFromName, cityToName);

    // Return values with city names to parent component through onSearch
    onSearch({
      countryFromId,
      cityFromId,
      countryToId,
      cityToId,
      cityFromName,
      cityToName,
    });
    onClose();
  };

  // handle close
  const handleClose = () => {
    onClose();
  };

  // disable search button before locations are selected in the fields
  const isSearchDisabled = !fromLocation || !toLocation;

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiModal-backdrop": {
          backgroundColor: "rgba(0, 0, 0, 0.90);",
        },
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "var(--tg-theme-bg-color)",
          width: "90%",
          p: 2,
          borderRadius: "10px",
          padding: "0px",
          margin: "0px",
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            margin: "0px",
            padding: "15px",
            margin: "10px",
            borderRadius: "10px",
          }}
        >
          <Typography variant="h6" gutterBottom>
            {t("mainList.searchModal.title")}
          </Typography>

          <Autocomplete
            options={fromLocationSuggestions || []}
            getOptionLabel={(option) =>
              userLanguage === "ru"
                ? option?.place || option?.place_en
                : option?.place_en || ""
            }
            value={fromLocation}
            onChange={(event, newValue) => setFromLocation(newValue)}
            onInputChange={(event, newInputValue) =>
              setFromLocationQuery(newInputValue)
            }
            sx={{
              marginBottom: "10px",
            }}
            renderInput={(params) => (
              <TextField {...params} label={t("mainList.searchModal.fromLocation")} />
            )}
            noOptionsText={t("locationSearch.field.startTyping")}
          />

          <Autocomplete
            options={toLocationSuggestions || []}
            getOptionLabel={(option) =>
              userLanguage === "ru"
                ? option?.place || option?.place_en
                : option?.place_en || ""
            }
            value={toLocation}
            onChange={(event, newValue) => setToLocation(newValue)}
            onInputChange={(event, newInputValue) =>
              setToLocationQuery(newInputValue)
            }
            renderInput={(params) => (
              <TextField {...params} label={t("mainList.searchModal.toLocation")} />
            )}
            noOptionsText={t("locationSearch.field.startTyping")}
          />

          <Box mt={2} sx={{ display: "flex", justifyContent: "space-between" }}>
            <Button variant="outlined" onClick={handleClose}>
            {t("mainList.searchModal.cancelButton")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={passProps}
              disabled={isSearchDisabled}
            >
              {t("mainList.searchModal.searchButton")}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default SearchModal;
