import React from "react";
import { withTranslation } from "react-i18next";
import { Box, Typography, List, ListItem, Link } from "@mui/material";
import { StyledBox } from "../styles";

const AboutUs = ({ t }) => {
  const userLanguage = sessionStorage.getItem("user_language");

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      {/* Heading Container */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              alt={"Whoflies.ru logotype"}
              src={"./logo.png"}
              style={{ marginTop: "10px", width: "30%", marginBottom: "10px" }}
            />
            <Typography variant="subtitle1">{t("aboutApp.title")}</Typography>
          </Box>
          
      <StyledBox>
        {/* Content Container */}
        {userLanguage === "ru" ? (
          <>
            <Typography variant="h6">Наша миссия</Typography>
            <Typography variant="body1">
              Объединить путешественников и отправителей для безопасной и
              эффективной доставки посылок в любую точку мира.
            </Typography>
            <br />
            <Typography variant="h6">Как это работает?</Typography>
            <Typography variant="body1">Для отправителей:</Typography>
            <List sx={{ listStyleType: "disc", listStylePosition: "inside" }}>
              <ListItem sx={{ display: "list-item" }}>
                Создавайте новые объявления с маршрутом, датами и информации о
                посылки, с вами обязательно свяжутся.
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                Находите путешественников в приложении, направляющихся по
                необходимому маршруту.
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                Спишитесь напрямую в Telegram, обсудите детали, такие как
                вознаграждение и процесс получения посылки в точках отправления
                и прибытия.
              </ListItem>
            </List>
            <Typography variant="body1">Для путешественников:</Typography>
            <List sx={{ listStyleType: "disc", listStylePosition: "inside" }}>
              <ListItem sx={{ display: "list-item" }}>
                Опубликуйте своё путешествие, опишите что готовы взять с собой и
                на каких условиях.
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                Посмотрите текущие заказы, вероятно кто-то уже ищет
                путешественника по вашему маршруту.
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                Свяжитесь с заказчиком напрямую в Telegram, уточните детали и
                договоритесь о вознаграждении учитывая объем, вес и другие
                нюансы.
              </ListItem>
            </List>
            <br />
            <Typography variant="h6">
              Безопасность и меры предосторожности
            </Typography>
            <List sx={{ listStyleType: "disc", listStylePosition: "inside" }}>
              <ListItem sx={{ display: "list-item" }}>
                Помните, ваша безопасность превыше всего. Мы настоятельно
                категорически не рекомендуем брать ничего запечатанного, будь то
                новый iPhone в подарок другу, конверт с завещанием или пачка с
                рисом.
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                Доверяйте своим инстинктам. Почувствовали что-то неладное -
                смело отказывайтесь от перевозки посылки.
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                Ни в коем случае не соглашайтесь на предоплату и не переводите
                деньги до встречи с человеком, а в идеале до факта передачи
                посылки из рук в руки. В последнее время участились случаи
                мошенничества.
              </ListItem>
            </List>
            <br />
            <Typography variant="h6">
              Условия использования приложения
            </Typography>
            <Typography variant="body1">
              Whoflies является площадкой для размещения объявлений и не несет
              ответственности за переданные посылки. Приложение не обрабатывает
              денежные средства пользователей и не несет ответственности за
              возможные риски. Пользователи, как отправители, так и перевозчики,
              осознают и принимают все связанные риски.
            </Typography>
            <br />
            <Typography variant="h6">Официальные каналы и сайт</Typography>
            <Typography variant="body1">
              Будьте в курсе наших последних новостей:
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="h6">Our Mission</Typography>
            <Typography variant="body1">
              Whoflies, a non-profit application, connects authenticated
              travelers with senders for secure and efficient worldwide parcel
              delivery.
            </Typography>
            <br />
            <Typography variant="h6">How It Works</Typography>
            <Typography variant="body1">For senders:</Typography>
            <List sx={{ listStyleType: "disc", listStylePosition: "inside" }}>
              <ListItem sx={{ display: "list-item" }}>
                Post your delivery orders and specify your requirements.
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                Communicate directly to discuss details and ensure a smooth
                delivery process.
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                Connect with available travelers heading in your package's
                direction.
              </ListItem>
            </List>
            <Typography variant="body1">For travelers:</Typography>
            <List sx={{ listStyleType: "disc", listStylePosition: "inside" }}>
              <ListItem sx={{ display: "list-item" }}>
                Browse and engage with delivery orders on your route.
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                Directly communicate with senders within the app to finalize
                details.
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                Set your personalized delivery fee based on the size and effort.
              </ListItem>
            </List>
            <br />
            <Typography variant="h6">Safety & Guidelines</Typography>
            <List sx={{ listStyleType: "disc", listStylePosition: "inside" }}>
              <ListItem sx={{ display: "list-item" }}>
                Prioritize safety; no sealed packages allowed.
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                Trust your instincts; your well-being comes first.
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                Never pay in advance
              </ListItem>
            </List>
            <br />
            <Typography variant="h6">Privacy & Security</Typography>
            <Typography variant="body1">
              Whoflies just provides information and does not assume
              responsibility for the parcels exchanged. The app does not handle
              user funds and is not liable for potential risks. Users, both
              senders, and carriers, acknowledge and assume all associated
              risks.
            </Typography>
            <br />
            <Typography variant="h6">Official Channels & Website</Typography>
            <Typography variant="body1">
              Stay updated with our official channels where we post news about
              the new features in Whoflies.
            </Typography>
          </>
        )}
        <List>
          <ListItem>
            <Link href="https://t.me/whoflies_news" underline="none">
              {t("aboutApp.connect.channelEn")}
            </Link>
          </ListItem>
          <ListItem>
            <Link href="https://t.me/whoflies_official" underline="none">
              {t("aboutApp.connect.channelRu")}
            </Link>
          </ListItem>
          <ListItem>
            <Link href="https://whoflies.ru/" underline="none">
              {t("aboutApp.connect.website")}
            </Link>
          </ListItem>
        </List>
      </StyledBox>
    </Box>
  );
};

export default withTranslation()(AboutUs);
