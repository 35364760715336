//telegramproxy.service.js

import axiosInstance from "../axios-common";

class TelegramProxyDataService {

  getImageById(userId) {
    return axiosInstance.get(`/telegram/getUserProfilePhotos`, {
      params: {
        userId,
      },
    });
  }

}

const TelegramProxyService = new TelegramProxyDataService();

export default TelegramProxyService;
