// App.js

import React from "react";
import { I18nextProvider } from "react-i18next";
import { useTranslation } from "react-i18next";
import Container from "@mui/material/Container";
import "./App.css";
import "./fontImports";

// DATE LOCALIZATION
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/ru";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

// COMPONENTS
import AppRoutes from "./AppRoutes";
import ButtonAppBar from "./components/ui/navbar";

// HOOKS
import { useDetectUserLanguage } from "./hooks/useDetectUserLanguage";
import { useAuthenticate } from "./hooks/useAuthenticate";
import { initGA } from "./services/ganalytics.service";
import WebApp from "@twa-dev/sdk";

const App = () => {
  const { i18n } = useTranslation();
  const { isAuthenticated, isAdmin, userId } = useAuthenticate();
  const { userLanguage } = useDetectUserLanguage();

  WebApp.expand();
  
  const dateFormat = userLanguage === "ru" ? "ru" : "en";
  dayjs.locale(dateFormat);
  dayjs.extend(utc);
  dayjs.extend(timezone);

  if (isAuthenticated && userId) {
    initGA(userId);
  }

  return (
    <I18nextProvider i18n={i18n}>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={userLanguage}
      >
        <Container disableGutters>
          <ButtonAppBar />
          <AppRoutes isAdmin={isAdmin} isAuthenticated={isAuthenticated} />
        </Container>
      </LocalizationProvider>
    </I18nextProvider>
  );
};

export default App;
