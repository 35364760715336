// sendTelegramMessage.js
import StatDataService from "./../services/stat.service";

const sendTelegramMessage = async (
  telegramUsername,
  phoneNumber,
  userId,
  authorId,
  postType,
  orderId,
  tripId,
  t
) => {
  if (!telegramUsername && !phoneNumber) {
    console.error("Both telegramUsername and phoneNumber are empty or null.");
    return;
  }

  const confirmationMessage = t("postModal.button.sendMessageConfirmation");

  let telegramLink;
  if (telegramUsername && telegramUsername.trim() !== "") {
    telegramLink = "https://t.me/" + telegramUsername;
  } else if (phoneNumber && phoneNumber.trim() !== "") {
    telegramLink = "https://t.me/" + phoneNumber;
  }

  if (window.confirm(confirmationMessage)) {
    const interactionDate = new Date().toISOString();

    // Define data with nullable orderId and tripId based on postType
    const dataToSend = {
      userId,
      authorId,
      postType,
      interactionDate,
      orderId: postType === "order" ? orderId : null,
      tripId: postType === "trip" ? tripId : null,
    };

    console.log("dataToSend", dataToSend)
    // Record the interaction using the StatDataService method
    try {
      await StatDataService.recordInteraction(dataToSend);

      // Open the Telegram link in a new tab
      window.open(telegramLink, "_blank");
    } catch (error) {
      console.error("Error recording interaction:", error);
    }
  }
};

export default sendTelegramMessage;
