//geo-name.service.js
import axios from 'axios'

class GeoNameService {
  getCountryById (id) {
    return axios.get(`/api/places/countries/${id}`)
  }

  getCityById (id) {
    return axios.get(`/api/places/cities/${id}`)
  }

  getLocationSuggestions (query) {
    return axios.get(`/api/places?query=${query}`)
  }
}

const geoNameService = new GeoNameService();

export default geoNameService;
