// StartChat.js
const StartChat = async (
  telegramUsername,
  phoneNumber
) => {

  if (!telegramUsername && !phoneNumber) {
    console.error("Both telegramUsername and phoneNumber are empty or null.");
    return;
  }

  let telegramLink;
  if (telegramUsername && telegramUsername.trim() !== "") {
    telegramLink = "https://t.me/" + telegramUsername;
  } else if (phoneNumber && phoneNumber.trim() !== "") {
    telegramLink = "https://t.me/" + phoneNumber;
  }

  if (window.confirm("Are you sure you want to open chat with user?")) {
    try {
      window.open(telegramLink, "_blank");
    } catch (error) {
      console.error("Error opening chat:", error);
    }
  }
};

export default StartChat;
