import React, { useEffect, useState } from "react";
import UserService from "../services/user.service";
import { useTranslation } from "react-i18next";
import AvatarUrlComponent from "./ui/avatar";
import { StyledBox } from "../styles";
import { Link, Box, Stack, Typography, IconButton } from "@mui/material";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import PhoneIcon from "@mui/icons-material/Phone";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import Alert from "@mui/material/Alert";
import RequestPhoneNumber from "./requestPhoneNumber";

const UserProfile = () => {
  const { t } = useTranslation();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const userId = parseInt(sessionStorage.getItem("user_id"));
  const [phoneNotUpdated, setPhoneNotUpdated] = useState(true);

  useEffect(() => {
    fetchUserData();
  }, []);

  useEffect(() => {
    // Refetch user data when phoneNotUpdated changes
    if (!phoneNotUpdated) {
      fetchUserData();
    }
  }, [phoneNotUpdated]);

  const fetchUserData = async () => {
    try {
      const response = await UserService.getUserById(userId);
      setUser(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching user data:", error);
      setLoading(false);
    }
  };

  const handleClick = () => {
    console.log("Clicked!");
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Stack direction="column" alignItems="center" spacing={1}>
        <Box>
          <AvatarUrlComponent
            telegramId={sessionStorage.getItem("telegramId")}
            className="profile-avatar"
          />
        </Box>
        <Typography variant="body1">
          {user && user.firstName ? user.firstName : "Traveller"}
        </Typography>
      </Stack>

      {/* {loading ? (
        <CircularProgress />
      ) : user ? ( */}
      <Stack
        sx={{ width: "100%", paddingLeft: "10px", paddingRight: "10px" }}
        spacing={2}
      >
        <RequestPhoneNumber
          inputsDisabled={phoneNotUpdated}
          setInputsDisabled={setPhoneNotUpdated}
        />
      </Stack>
      <StyledBox>
        <Typography variant="h6">{t("profile.account.title")}</Typography>

        <UserInfoLine
          icon={<AccountCircleRoundedIcon />}
          label={t("profile.account.firstname")}
          value={user?.firstName || t("profile.account.notProvided")}
          onClick={handleClick}
        />
        <UserInfoLine
          icon={<AlternateEmailIcon />}
          label={t("profile.account.username")}
          value={
            user?.telegramUsername && user?.telegramUsername !== "0"
              ? `@${user.telegramUsername}`
              : t("profile.account.notProvided")
          }
          onClick={handleClick}
        />
        <UserInfoLine
          icon={<PhoneIcon />}
          label={t("profile.account.phonenumber")}
          value={
            user?.phoneNumber && user?.phoneNumber !== "0"
              ? user?.phoneNumber
              : t("profile.account.notProvided")
          }
          onClick={handleClick}
        />
      </StyledBox>

      {/* ) : (
        <Typography variant="body1">User not found</Typography>
      )} */}
    </Box>
  );
};

const UserInfoLine = ({ icon, label, value, onClick }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <IconButton size="small" edge="start">
          {icon}
        </IconButton>
        <Typography sx={{ marginLeft: 1 }}>{label}</Typography>
      </Box>
      <Link
        //href="#"
        underline="hover"
        sx={{ textAlign: "right" }}
      >
        {value}
      </Link>
    </Box>
  );
};

export default UserProfile;
