import React from "react";
import { Routes, Route } from "react-router-dom";

// COMPONENTS
import ListWrapper from "./components/ListWrapper";
import TripRedirect from "./components/trip-redirect";
import OrderRedirect from "./components/order-redirect";
import NotAuthorizedPage from "./components/not-authorized";
import UserProfile from "./components/UserProfile";
import CreatePost from "./components/CreatePost";
import EditPost from "./components/EditPost";
import Support from "./components/Support";
import AboutUs from "./components/AboutUs";
import UserList from "./components/admin/UserList";

const AppRoutes = ({ isAuthenticated, isAdmin }) => {
  return (
    <Routes>
      <Route path="/trip/:id" element={<TripRedirect />} />
      <Route path="/order/:id" element={<OrderRedirect />} />
      {isAuthenticated ? (
        <>
          <Route path="/" element={<ListWrapper />} />
          <Route
            path="/orders"
            element={<ListWrapper initialTab={0} myPosts={true} />}
          />
          <Route
            path="/trips"
            element={<ListWrapper initialTab={1} myPosts />}
          />
          <Route path="/orders/:id" element={<ListWrapper initialTab={0} />} />
          <Route path="/trips/:id" element={<ListWrapper initialTab={1} />} />
          <Route path="/createpost" element={<CreatePost />} />
          <Route path="/user/:id" element={<UserProfile />} />
          <Route
            path="/trips/edit/:postId"
            element={<EditPost postType="trip" />}
          />
          <Route
            path="/orders/edit/:postId"
            element={<EditPost postType="order" />}
          />
          <Route path="/support" element={<Support />} />
          <Route path="/aboutus" element={<AboutUs />} />
          {isAdmin && <Route path="/admin" element={<UserList />} />}
        </>
      ) : (
        <Route path="/*" element={<NotAuthorizedPage />} />
      )}
    </Routes>
  );
};

export default AppRoutes;
