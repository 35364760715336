// PostStatus.js

import React from "react";
import { useTranslation } from "react-i18next";

const PostStatus = ({ state }) => {
  const { t } = useTranslation();
  const statusNumber = Number(state);
  const getStatusName = (state) => {
    switch (state) {
      case 0:
        return t("postStatus.draft");
      case 1:
        return t("postStatus.published");
      case 2:
        return t("postStatus.unpublished");
      case 3:
        return t("postStatus.deleted");
      default:
        return t("postStatus.unknown");
    }
  };

  const statusName = getStatusName(statusNumber);

  return <>{statusName}</>;
};

export default PostStatus;
