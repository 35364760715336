// auth.service.js
import WebApp from '@twa-dev/sdk'
import axios from 'axios';

export const fetchDataForAuthentication = async () => {
  try {
    if (
      WebApp.initData &&
      WebApp.initData !== ''
    ) {
      const decodedInitDataString = decodeURIComponent(WebApp.initData);
      const responseData = await authenticateUser(decodedInitDataString);

      if (
        responseData.status === 'User authorized' &&
        responseData.user_id !== null &&
        responseData.telegramId !== null
      ) {
        return {
          status: responseData.status,
          user_id: responseData.user_id,
          telegramId: responseData.telegramId,
        };
      }
    }
  } catch (error) {
    console.error('Error during data fetching:', error);
    throw error;
  }
};


const authenticateUser = async (decodedInitDataString) => {
  try {
    const response = await axios.post('/api/authenticate', {
      initData: decodedInitDataString,
    });

    const responseData = response.data;
    return {
      status: responseData.status,
      user_id: responseData.user_id,
      telegramId: responseData.telegramId,
    };
  } catch (error) {
    console.error('Error during user authentication:', error);
    throw error;
  }
};

export const checkAdmin = async () => {
  try {
    const isAdminResponse = await axios.get('/api/admin/check');
    return {
      isAdmin: isAdminResponse.data.isAdmin,
    };
  } catch (error) {
    console.error('Error checking admin status:', error);
    throw error;
  }
};
