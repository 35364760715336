// ganalytics.service.js
import ReactGA from "react-ga4";

export const initGA = (userId) => {
  if (userId) {
    ReactGA.initialize("G-BM5R01GSM5", {
      gaOptions: {
        userId,
      },
    });
    ReactGA.send("pageview");
  }
};