//DetailModal.js

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AvatarUrlComponent from "./ui/avatar";
import TripDataService from "../services/trip.service";
import OrderDataService from "../services/order.service";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import dayjs from "dayjs";
// import DateFormatter from "./dateFormatter";
import SharePost from "./SharePost";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import geoNameService from "../services/geo-name.service";
import FlightIcon from "@mui/icons-material/Flight";
import ShareIcon from "@mui/icons-material/Share";
import TelegramIcon from "@mui/icons-material/Telegram";
import PublishToggleComponent from "./ui/PublishPostToggle";
import ListItemIcon from "@mui/material/ListItemIcon";
import EditIcon from "@mui/icons-material/Edit";
import FlagIcon from "@mui/icons-material/Flag";
import FavoriteIcon from "@mui/icons-material/Favorite";
import PublicIcon from "@mui/icons-material/Public";
import { StyledBox } from "../styles";
import sendTelegramMessage from "./sendTelegramMessage";
import PostStatus from "./PostStatus";

const DetailsModal = ({ listType, postId, onClose }) => {
  const navigate = useNavigate();
  const [postDetails, setPostDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [isPublished, setIsPublished] = useState(false);
  const userId = parseInt(sessionStorage.getItem("user_id"));
  const postType = listType === "orders" ? "order" : "trip";

  const userLanguage = sessionStorage.getItem("user_language");
  const { t } = useTranslation();

  useEffect(() => {
    const fetchPostDetails = async () => {
      try {
        let response;
        if (listType === "orders") {
          response = await OrderDataService.get(postId);
        } else if (listType === "trips") {
          response = await TripDataService.get(postId);
        }

        // Fetch common details
        const countryFromId = response.data.countryFromId;
        const countryFromResponse = await geoNameService.getCountryById(
          countryFromId
        );
        const countryFromName =
          userLanguage !== "ru"
            ? countryFromResponse.data.country_name_en
            : countryFromResponse.data.country_name_ru;

        const cityFromId = response.data.cityFromId;
        const cityFromResponse = await geoNameService.getCityById(cityFromId);
        const cityFromName =
          userLanguage !== "ru"
            ? cityFromResponse.data.city_name_en
            : cityFromResponse.data.city_name_ru;

        const countryToId = response.data.countryToId;
        const countryToResponse = await geoNameService.getCountryById(
          countryToId
        );
        const countryToName =
          userLanguage !== "ru"
            ? countryToResponse.data.country_name_en
            : countryToResponse.data.country_name_ru;

        const cityToId = response.data.cityToId;
        const cityToResponse = await geoNameService.getCityById(cityToId);
        const cityToName =
          userLanguage !== "ru"
            ? cityToResponse.data.city_name_en
            : cityToResponse.data.city_name_ru;

        setPostDetails({
          ...response.data,
          countryFromName,
          cityFromName,
          countryToName,
          cityToName,
        });

        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching post details:", error);
        setIsLoading(false);
      }
    };

    if (postId) {
      fetchPostDetails();
    }
  }, [listType, postId]);

  if (!postDetails) {
    return null; // Return null or loading indicator while data is loading
  }

  // update post details when published/unpublished by child component
  const updatePostState = (newState) => {
    setPostDetails((prevState) => ({
      ...prevState,
      state: newState,
    }));
  };

  // Additional menu handlers
  const handleMenuOpen = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const handleEdit = () => {
    // Handle edit logic
  };

  console.log("postDetails.state", postDetails);

  return (
    <Modal
      open={!!postDetails}
      onClose={onClose}
      sx={{
        "& .MuiModal-backdrop": {
          backgroundColor: "rgba(0, 0, 0, 0.95);",
        },
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "var(--tg-theme-section-bg-color)",
          width: "90%",
          minHeight: "300px",
          p: 2,
          borderRadius: "10px",
          padding: "0px",
          margin: "0px",
        }}
      >
        {isLoading ? (
          <Typography>Loading...</Typography>
        ) : (
          <>
            {/* Header Section */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                color: "var(--tg-theme-text-color)",
                margin: "0px",
                padding: "10px 5px 10px 5px",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
              }}
            >
              <IconButton onClick={onClose}>
                <ArrowBackIcon />
              </IconButton>

              {postDetails.telegramId && (
                <AvatarUrlComponent
                  telegramId={postDetails.telegramId}
                  className="modal-avatar"
                />
              )}

              <Box sx={{ marginLeft: "10px" }}>
                <Typography variant="body1" fontWeight="bold">
                  {listType === "trips"
                    ? t("postModal.header.title.trip")
                    : t("postModal.header.title.order")}
                </Typography>

                {/* add t("postModal.header.viewProfile") for non-authored posts after extending profile information in backend */}
                <Typography variant="body2">
                  {userId !== postDetails.authorId ? (
                    <span>
                      {t("postModal.header.datePublished")}{" "}
                      {dayjs(postDetails.datePublished).format("DD MMMM")}
                    </span>
                  ) : userId === postDetails.authorId ? (
                    <PostStatus state={postDetails.state} />
                  ) : null}
                </Typography>
              </Box>
              <Box sx={{ marginLeft: "auto" }}>
                <IconButton onClick={handleMenuOpen}>
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={menuAnchor}
                  open={Boolean(menuAnchor)}
                  onClose={handleMenuClose}
                >
                  {userId === postDetails.authorId && (
                    <MenuItem>
                      <ListItemIcon>
                        <PublicIcon />
                      </ListItemIcon>
                      <Typography variant="inherit">
                        {t("postModal.menu.publish")}
                      </Typography>
                      <ListItemIcon style={{ marginLeft: "auto" }}>
                        <PublishToggleComponent
                          postDetails={postDetails}
                          postType={postType}
                          updatePostState={updatePostState}
                        />
                      </ListItemIcon>
                    </MenuItem>
                  )}
                  {userId === postDetails.authorId && (
                    <MenuItem
                      onClick={() => navigate(`/${postType}s/edit/${postId}`)}
                    >
                      <ListItemIcon>
                        <EditIcon />
                      </ListItemIcon>
                      {t("postModal.menu.edit")}
                    </MenuItem>
                  )}
                  {userId !== postDetails.authorId && (
                    <>
                      <MenuItem>
                        <ListItemIcon>
                          <FavoriteIcon />
                        </ListItemIcon>
                        {t("postModal.menu.save")}
                      </MenuItem>
                      <MenuItem>
                        <ListItemIcon>
                          <FlagIcon />
                        </ListItemIcon>
                        {t("postModal.menu.report")}
                      </MenuItem>
                    </>
                  )}
                </Menu>
              </Box>
            </Box>

            {/* Location Details Section */}
            <StyledBox
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                <Typography align="left">
                  <span
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {postDetails.cityFromName}
                  </span>{" "}
                  <br />
                  {postDetails.countryFromName}
                  {postType === "trip" && (
                    <Typography variant="subtitle2">
                      {dayjs(postDetails.date_departure).format("DD MMMM")}
                    </Typography>
                  )}
                </Typography>
              </Box>
              <Box>
                <Typography align="center">
                  <FlightIcon
                    sx={{
                      transform: "rotate(90deg)",
                      color: "var(--tg-theme-button-color)",
                    }}
                  />
                </Typography>
              </Box>

              <Box>
                <Typography align="right">
                  <span style={{ fontWeight: "bold" }}>
                    {postDetails.cityToName}
                  </span>{" "}
                  <br />
                  {postDetails.countryToName}
                  {postType === "trip" && (
                    <Typography variant="subtitle2">
                      {dayjs(postDetails.date_arrival).format("DD MMMM")}
                    </Typography>
                  )}
                </Typography>
              </Box>
            </StyledBox>
            {/* Description Section */}
            <StyledBox
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box>
                <Typography align="left" className="modalDescriptionContainer" sx={{
                  WebkitBoxOrient: "vertical",
                  display: "-webkit-box",
                  WebkitLineClamp: 6,
                  overflow: "auto",
                }}>
                  <span
                    style={{
                      color: "var(--tg-theme-text-color)",
                    }}
                  >
                    {postDetails.description}
                  </span>
                </Typography>
              </Box>
            </StyledBox>
            {/* DateBy Section */}
            {postType === "order" && (
              <StyledBox
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography align="left">
                    <span
                      style={{
                        color: "var(--tg-theme-text-color)",
                      }}
                    >
                       {t("postModal.deliverBy")} {dayjs(postDetails.dateBy).format("DD MMMM")}
                    </span>
                  </Typography>
                </Box>
              </StyledBox>
            )}
            {/* Buttons Section */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "15px",
                margin: "10px",
              }}
            >
              <SharePost
                type={postType === "order" ? "order" : "trip"}
                id={postDetails.id}
                cityFrom={postDetails.cityFromName}
                countryFrom={postDetails.countryFromName}
                cityTo={postDetails.cityToName}
                countryTo={postDetails.countryToName}
                description={postDetails.description}
                date={
                  postType === "order"
                    ? postDetails.dateBy
                    : postDetails.date_departure
                }
              />
              {userId !== postDetails.authorId && (
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<TelegramIcon />}
                  disabled={
                    !postDetails.telegramUsername && !postDetails.phoneNumber
                  }
                  onClick={() =>
                    sendTelegramMessage(
                      postDetails.telegramUsername || null,
                      postDetails.phoneNumber || null,
                      userId,
                      postDetails.authorId,
                      postType,
                      postType === "order" ? postDetails.id : null,
                      postType === "trip" ? postDetails.id : null,
                      t
                    )
                  }
                  helperText="test"
                >
                  {t("orderDetailsModal.buttons.sendMessageButton")}
                </Button>
              )}
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default DetailsModal;
