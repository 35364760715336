// PublishToggleComponent.js

import React, { useState, useEffect } from "react";
import { Switch } from "@mui/material";
import {
  dateArrivalValidator,
  dateDepartureValidator,
  postValidator,
} from "./FormValidator";
import dayjs from "dayjs";
import OrderService from "../../services/order.service";
import TripService from "../../services/trip.service";

const PublishToggleComponent = ({ postDetails, postType, updatePostState }) => {
  const [postPublished, setPostPublished] = useState(postDetails.state === "1");
  const [isSwitchDisabled, setIsSwitchDisabled] = useState(false);
  const validationErrors = postValidator(postDetails);

  useEffect(() => {
    const checkDates = () => {
      const now = dayjs();
      if (postType === "order") {
        setIsSwitchDisabled(dayjs(postDetails.dateBy).isBefore(now));
      } else if (postType === "trip") {
        setIsSwitchDisabled(
          dayjs(postDetails.date_departure).isBefore(now) ||
            dayjs(postDetails.date_arrival).isBefore(now)
        );
      }
    };

    if (postDetails.state !== "1") {
      checkDates();
    }
  }, [postDetails, postType]);

  const handlePublishSwitch = async () => {
    try {
      const targetState = postPublished ? "2" : "1";

      switch (postType) {
        case "order":
          const updatedOrderData = {
            state: targetState,
            description: postDetails.description,
            dateBy: postDetails.dateBy,
            countryFromId: postDetails.countryFromId,
            cityFromId: postDetails.cityFromId,
            countryToId: postDetails.countryToId,
            cityToId: postDetails.cityToId,
            datePublished:
              targetState === "1"
                ? new Date().toISOString()
                : postDetails.datePublished,
          };

          await OrderService.update(postDetails.id, updatedOrderData);
          setPostPublished(!postPublished);
          updatePostState(targetState);
          break;

        case "trip":
          const updatedTripData = {
            state: targetState,
            description: postDetails.description,
            dateDeparture: postDetails.dateDeparture,
            dateArrival: postDetails.dateArrival,
            countryFromId: postDetails.countryFromId,
            cityFromId: postDetails.cityFromId,
            countryToId: postDetails.countryToId,
            cityToId: postDetails.cityToId,
            datePublished:
              targetState === "1"
                ? new Date().toISOString()
                : postDetails.datePublished,
          };

          // Update trip data with specific fields
          await TripService.update(postDetails.id, updatedTripData);
          setPostPublished(!postPublished); // Toggle postPublished state
          updatePostState(targetState);
          break;

        default:
          console.error("Unsupported post type for publishing/unpublishing.");
          break;
      }
    } catch (error) {
      console.error("Error publishing/unpublishing post:", error);
    }
  };

  if (Object.keys(validationErrors).length === 0) {
    console.log("data is valid");
  } else {
    console.log("Validation errors:", validationErrors);
  }

  return (
      <Switch
        checked={postPublished}
        onChange={handlePublishSwitch}
        disabled={isSwitchDisabled}
      />
  );
};

export default PublishToggleComponent;
