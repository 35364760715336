// CreatePostModal.js

import React from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Box,
  DialogTitle,
} from "@mui/material";

const CreatePostModal = ({
  isOpen,
  onClose,
  isTripCreated,
  isOrderCreated,
  postError,
  t,
  postType,
}) => {
  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") {
      // Prevent closing if backdrop is clicked
      return;
    }
    onClose(event, reason);
  };

  const handleReloadPage = () => {
    window.location.reload();
  };

  const handleNavigation = () => {
    const myOrdersUrl = "/app/orders?myPosts=true";
    const myTripsUrl = "/app/trips?myPosts=true";
    window.location.href = isOrderCreated ? myOrdersUrl : myTripsUrl;
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>
        {isTripCreated
          ? t("new-post.modal.tripSuccessTitle")
          : isOrderCreated
          ? t("new-post.modal.orderSuccessTitle")
          : t("new-post.modal.errorTitle")}
      </DialogTitle>
      <DialogContent>
        {isTripCreated || isOrderCreated ? (
          <Box>
            {isTripCreated
              ? t("new-post.modal.tripSuccessMessage")
              : t("new-post.modal.orderSuccessMessage")}
          </Box>
        ) : (
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                alt={"The post has not been created"}
                src={"./images/errorState.png"}
                style={{ width: "30%" }}
              />
            </Box>
            <Box>
              {postType === "trip"
                ? t("new-post.modal.tripErrorMessage")
                : t("new-post.modal.orderErrorMessage")}
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {isTripCreated || isOrderCreated ? (
          <>
            <Button
              variant="primary"
              onClick={handleNavigation}
            >
              { isTripCreated ? t("new-post.modal.buttons.goToMyTrips") : t("new-post.modal.buttons.goToMyOrders")}
            </Button>
            <Button
              variant="secondary"
              onClick={handleReloadPage}
            >
              {t("new-post.modal.buttons.addNewPost")}
            </Button>
          </>
        ) : (
          <Button
            variant="primary"
            onClick={onClose}
          >
            {t("new-post.modal.buttons.tryAgain")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CreatePostModal;
