// admin.service.js

import axiosInstance from "../axios-common";

class AdminDataService {
  get() {
    return axiosInstance.get(`/admin/users`);
  }
}

const AdminService = new AdminDataService();
export default AdminService;
