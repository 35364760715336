import React, { useState, useEffect } from "react";
import AvatarUrlComponent from "../ui/avatar";
import { useTranslation } from "react-i18next";
import AdminService from "../../services/admin.service";
import { DataGrid } from "@mui/x-data-grid";
import StartChat from "./StartChat";
import {
  Box,
  Typography,
  List,
  ListItem,
  Link,
  Modal,
  Button,
} from "@mui/material";
import { StyledBox } from "../../styles";

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const { t } = useTranslation();

  const handleCellClick = (params, event) => {
    setOpenModal(true);
    setSelectedUser(params.row); // Access clicked user's data
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedUser(null);
  };

  useEffect(() => {
    retrieveUsers();
  }, []);

  const retrieveUsers = async () => {
    try {
      const response = await AdminService.get();
      setUsers(
        response.data.map((user) => ({
          id: user.userId,
          telegramId: user.telegramId,
          joinedBot: user.stats?.[0]?.joinedBot || null,
          visitedWeb: user.stats?.[0]?.visitedWeb || 0,
          lastLogin: user.stats?.[0]?.lastLogin || null,
          ...user,
        }))
      );
    } catch (error) {
      console.error("Error retrieving users:", error);
    }
  };

  const columns = [
    {
      field: "telegramId",
      headerName: "TID",
      width: 30,
      renderCell: (params) => (
        <AvatarUrlComponent
          telegramId={params.row.telegramId}
          className="admin-userlist-avatar"
        />
      ),
    },
    { field: "userId", headerName: "ID", width: 30 },
    { field: "telegramUsername", headerName: "Username", width: 100 },
    {
      field: "lastLogin",
      headerName: "Last Login",
      width: 140,
      valueFormatter: (params) =>
        params.value
          ? new Date(params.value).toLocaleString("ru-RU", {
              dateStyle: "short",
              timeStyle: "short",
            })
          : "",
    },
    { field: "phoneNumber", headerName: "Phone Number", width: 150 },
    {
      field: "agreement_accepted",
      headerName: "Agreement Accepted",
      type: "boolean",
      width: 150,
    },
    {
      field: "joinedBot",
      headerName: "Joined Bot At",
      width: 150,
      valueFormatter: (params) =>
        params.value
          ? new Date(params.value).toLocaleString("ru-RU", {
              dateStyle: "short",
              timeStyle: "medium",
            })
          : "",
    },
    { field: "visitedWeb", headerName: "Website Visits", width: 120 },
  ];

  return (
    <>
      <Box sx={{ overflowX: "auto" }} className="admin-UserList">
        {users.length > 0 ? (
          <DataGrid
            rows={users}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 7,
                },
              },
            }}
            pageSizeOptions={[7]}
            autoHeight
            autoPageSize
            onCellClick={handleCellClick}
          />
        ) : (
          <Typography variant="h5">Loading users...</Typography>
        )}
      </Box>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            bgcolor: "background.default",
            borderRadius: "10px",
          }}
        >
          <StyledBox>
            <Typography variant="h6">User Information</Typography>

            <UserInfoLine
              // icon={<AccountCircleRoundedIcon />}
              label="System User ID"
              value={selectedUser?.userId}
            />
            <UserInfoLine
              // icon={<AccountCircleRoundedIcon />}
              label="Telegram ID"
              value={selectedUser?.telegramId}
            />
            <UserInfoLine
              label="Telegram Username"
              value={selectedUser?.telegramUsername !== "0" ? selectedUser?.telegramUsername : "Not provided" }
            />
            <UserInfoLine
              label="Phone Number"
              value={selectedUser?.phoneNumber !== "0" ? selectedUser?.phoneNumber : "Not provided"}
            />
            <UserInfoLine
              label="Joined Bot Date"
              value={
                selectedUser?.lastLogin
                  ? new Date(selectedUser.joinedBot).toLocaleString("ru-RU", {
                      dateStyle: "short",
                      timeStyle: "short",
                    })
                  : "N/A"
              }
            />
            <UserInfoLine
              label="Last Login"
              value={
                selectedUser?.lastLogin
                  ? new Date(selectedUser.lastLogin).toLocaleString("ru-RU", {
                      dateStyle: "short",
                      timeStyle: "short",
                    })
                  : "N/A"
              }
            />
            <UserInfoLine
              label="Number of Visits"
              value={selectedUser?.visitedWeb > 0 ? selectedUser?.visitedWeb : "N/A"}
            />
            <UserInfoLine
              label="Agreement Accepted"
              value={selectedUser?.agreement_accepted ? "Yes" : "No"}
            />
          </StyledBox>

          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                StartChat(
                  selectedUser.telegramUsername || null,
                  selectedUser.phoneNumber || null
                )
              }
              sx={{ margin: "10px;" }}
            >
              START CHAT
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

const UserInfoLine = ({ icon, label, value, onClick }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {/* <IconButton size="small" edge="start">
          {icon}
        </IconButton> */}
        <Typography>{label}</Typography>
      </Box>
      <Link
        //href="#"
        underline="hover"
        sx={{ textAlign: "right" }}
      >
        {value}
      </Link>
    </Box>
  );
};

export default UserList;
