//FormLocation.js

import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import GeoNameService from "../../services/geo-name.service";
import { defaultLocations } from "./defaultLocations";
import { useTranslation } from "react-i18next";

const LocationAutocomplete = ({
  onChange,
  userLanguage,
  placeholder,
  label,
  error,
  value,
  editMode,
}) => {
  const { t } = useTranslation();
  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isRequiredError, setIsRequiredError] = useState(false);

  const handleChange = (event, newInputValue) => {
    setQuery(newInputValue);
    fetchSuggestions(newInputValue);
  };

  const fetchSuggestions = async (inputValue) => {
    setIsLoading(true);
    try {
      // to test loading and empty states
      // await new Promise((resolve) => setTimeout(resolve, 5000));
      const response = await GeoNameService.getLocationSuggestions(inputValue);
      const suggestions = response.data;
      setSuggestions(suggestions);
    } catch (error) {
      console.error("Error fetching location suggestions:", error);
    } finally {
      setIsLoading(false); // Set loading to false after fetching
    }
  };

  useEffect(() => {
    if (error) {
      setIsRequiredError(true);
    }
  }, [error]);

  return (
    <Autocomplete
      options={query.length > 2 ? suggestions || [] : []}
      //options={query ? suggestions || [] : defaultLocations()} // it works, but need to look carefully into helpText and noOptionText
      getOptionLabel={(option) =>
        userLanguage === "ru" ? option.place : option.place_en
      }
      value={value}
      inputValue={query}
      onChange={(event, selectedOption, reason) => {
        setIsRequiredError(false);
        onChange(selectedOption); // <-- Propagate selected location
      }}
      onInputChange={handleChange}
      renderOption={(props, option) => (
        <li {...props} key={option.city_id}>
          {userLanguage === "ru" ? option.place : option.place_en}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          error={isRequiredError}
          helperText={
            isRequiredError
              ? t("locationSearch.field.emptyError")
              : undefined
          }
        />
      )}
      //enable until found the solution to add handler to clear button
      disableClearable={true}
      // keep until figured out how to deal with isOptionEqual
      disabled={editMode}
      loading={isLoading}
      loadingText={`${t("locationSearch.field.searching")} ${query}`}
      noOptionsText={
        (query ?? "").length > 0
          ? query.length < 3
            ? t("locationSearch.field.continueTyping")
            : t("locationSearch.field.clearToSearch")
          :  t("locationSearch.field.startTyping")
      }
      //helperText={"choose from predefined"}
      // it works, but user don't to have select any specific value. Need additional validation in this case when submitting the form
      // freeSolo={true}

      // does not work, keep for syntax
      // isOptionEqualToValue={(selectedOption, value) => {
      //   console.log("option", selectedOption);
      //   console.log("value", value);
      //   return selectedOption.city_id === value.cityId;
      // }}

      // just keep it for a while as a stub
      isOptionEqualToValue={(option, value) => true}
    />
  );
};

export default LocationAutocomplete;
