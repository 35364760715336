import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: "en", //default
    fallbackLng: "en",
    debug: false,
    whitelist: ["ru", "en"],
    backend: {
      loadPath: "locales/{{lng}}.json",
      backendOptions: {
        expirationTime: 300000, // 5 minutes in milliseconds
      },
      requestOptions: {
        cache: "no-store",
      },
    },
  });

export default i18n;
