// ListComponent.js

import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import TripDataService from "../services/trip.service";
import OrderDataService from "../services/order.service";
import SearchModal from "./SearchModal";
import DetailsModal from "./ListPostModal";
import EmptyStateComponent from "./ListEmptyState";
import AvatarUrlComponent from "./ui/avatar";
import {
  NavigateNext as NavigateNextIcon,
  Route as RouteIcon,
} from "@mui/icons-material";
import {
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  Divider,
  Pagination,
  useTheme,
  Switch,
  FormControlLabel,
  Button,
  Box,
  Chip,
} from "@mui/material";
import PostStatus from "./PostStatus";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ITEMS_PER_PAGE = 4;

const ListComponent = ({ listType, myPosts }) => {
  const { t } = useTranslation();
  const userLanguage = sessionStorage.getItem("user_language");
  const { id: urlPostId } = useParams();
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedPost, setSelectedPost] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [showMyItems, setShowMyItems] = useState(false);
  const [showSearchChip, setShowSearchChip] = useState(false);
  const [cityFromName, setCityFromName] = useState(null);
  const [cityToName, setCityToName] = useState(null);
  const theme = useTheme();
  const backgroundColor = theme.palette.background.paper;
  const dividerColor = theme.palette.background.default;
  const userId = parseInt(sessionStorage.getItem("user_id"));
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedValue, setSelectedValue] = useState(
    showMyItems ? "myPosts" : "allPosts"
  ); // Initial value based on showMyItems

  useEffect(() => {
    // Fetch posts on load
    if (myPosts) {
      getPostsByAuthor();
      setShowMyItems(true);
    } else {
      getAllPosts();
      setShowMyItems(false);
    }

    // Open modal automatically if urlPostId is provided
    if (urlPostId) {
      setIsModalOpen(true);
      setSelectedPost(urlPostId);
    }
  }, [listType, urlPostId]);

  // FETCH ALL TRIPS OR ORDERS
  const getPostsByAuthor = async () => {
    try {
      let response;

      if (listType === "orders") {
        response = await OrderDataService.getOrdersByAuthorId(userId);
      } else if (listType === "trips") {
        response = await TripDataService.getTripsByAuthorId(userId);
      } else {
        throw new Error(`Invalid list type: ${listType}`);
      }

      setItems(response.data);
      setIsLoading(false);
      setTotalPages(Math.ceil(response.data.length / ITEMS_PER_PAGE));
    } catch (error) {
      console.error(`Error fetching posts by author:`, error);
      setIsLoading(false);
    }
  };

  // FETCH BY AUTHOR TRIPS OR ORDERS
  const getAllPosts = async () => {
    try {
      let response;

      if (listType === "orders") {
        response = await OrderDataService.getAll();
      } else if (listType === "trips") {
        response = await TripDataService.getAll();
      } else {
        throw new Error(`Invalid list type: ${listType}`);
      }

      setItems(response.data);
      setIsLoading(false);
      setTotalPages(Math.ceil(response.data.length / ITEMS_PER_PAGE));
    } catch (error) {
      console.error(`Error fetching all posts:`, error);
      setIsLoading(false);
    }
  };

  // ALL AND MY POSTS SWITCHER //
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFilterChange = (event) => {
    const selectedValue = event.currentTarget.dataset.myValue;

    // Update state and data based on the selected value
    setSelectedValue(selectedValue);
    setShowMyItems(selectedValue === "myPosts");

    if (selectedValue === "myPosts") {
      console.log("mytrips execution");
      setShowMyItems(true);
      handleDeleteSearchChip();
      getPostsByAuthor();
    } else {
      console.log("alltrips execution");
      setShowMyItems(false);
      handleDeleteSearchChip();
      getAllPosts();
      navigate("/", { search: "" });
    }
    handleClose();
  };

  // END OF ALL/MY POSTS SWITCHER //

  // HANDLE LOCATION SEARCH AND CHIP //
  const handleDeleteSearchChip = () => {
    setShowSearchChip(false);
  };

  const clearSearch = () => {
    setShowSearchChip(false);
    console.log("selval", selectedValue);
    if (selectedValue === "allPosts") {
      console.log("fetching all posts after clearing");
      getAllPosts();
    }
  };

  const handleSearchOpenModal = () => {
    setShowSearchModal(true);
  };

  const handleSearchCloseModal = () => {
    setShowSearchModal(false);
  };

  const handleSearch = ({
    countryFromId,
    cityFromId,
    countryToId,
    cityToId,
    cityFromName,
    cityToName,
  }) => {
    setCityFromName(cityFromName);
    setCityToName(cityToName);
    searchByRoute(countryFromId, cityFromId, countryToId, cityToId);
    setShowSearchChip(true);
  };

  const searchByRoute = async (
    countryFromId,
    cityFromId,
    countryToId,
    cityToId
  ) => {
    try {
      let response;
      if (listType === "orders") {
        // Make an API request to retrieve orders based on selected locations
        response = await OrderDataService.getOrdersByPlace(
          countryFromId,
          cityFromId,
          countryToId,
          cityToId
        );
      } else if (listType === "trips") {
        // Make an API request to retrieve trips based on selected locations
        response = await TripDataService.getTripsByPlace(
          countryFromId,
          cityFromId,
          countryToId,
          cityToId
        );
      }

      setItems(response.data);
      setIsLoading(false);
      setTotalPages(Math.ceil(response.data.length / ITEMS_PER_PAGE));
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };
  // END OF HANDLE LOCATION SEARCH AND CHIP //

  // POST MODAL HANDLE //
  const handleItemClick = (itemId) => {
    setIsModalOpen(true);
    setSelectedPost(itemId);
  };

  const handleCloseModal = () => {
    setSelectedPost(null);
    setIsModalOpen(false);

    if (urlPostId) {
      // Get back to Default list to avoid modal keep reopening from the url
      navigate("/", { search: "" });
    }
  };
  // END OF POST MODAL HANDLE //

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const startIndex = (page - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const visibleItems = items.slice(startIndex, endIndex);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "8px",
        }}
      >
        <Button
          variant="text"
          onClick={handleSearchOpenModal}
          disabled={showMyItems}
          sx={{ margin: "0px" }}
        >
          <RouteIcon /> {t("mainList.searchByRouteButton")}
        </Button>
        <Box sx={{ display: "inline-flex" }}>
          <Button
            variant="text"
            onClick={handleClick}
            endIcon={<ExpandMoreIcon />}
            disabled={isLoading}
          >
            {showMyItems
              ? listType === "trips"
                ? t("mainList.showMyOnlyToggle.myTrips")
                : t("mainList.showMyOnlyToggle.myOrders")
              : listType === "orders"
              ? t("mainList.showMyOnlyToggle.allOrders")
              : t("mainList.showMyOnlyToggle.allTrips")}
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem data-my-value="allPosts" onClick={handleFilterChange}>
              {listType === "trips"
                ? t("mainList.showMyOnlyToggle.allTrips")
                : t("mainList.showMyOnlyToggle.allOrders")}
            </MenuItem>
            <MenuItem data-my-value="myPosts" onClick={handleFilterChange}>
              {listType === "trips"
                ? t("mainList.showMyOnlyToggle.myTrips")
                : t("mainList.showMyOnlyToggle.myOrders")}
            </MenuItem>
          </Menu>
        </Box>
      </Box>
      <Box>
        {showSearchChip && (
          <Chip
            label={`${cityFromName} - ${cityToName}`}
            onDelete={clearSearch}
            sx={{marginBottom: "14px"}}
          ></Chip>
        )}
      </Box>

      {/* Empty State for different cases */}
      {!isLoading && items.length === 0 && (
        <EmptyStateComponent
          listType={listType}
          showMyItems={showMyItems}
          showSearchChip={showSearchChip}
        />
      )}

      {!isLoading && items.length > 0 && (
        <>
          {visibleItems.map((item, index) => (
            <React.Fragment key={item.id}>
              <ListItemButton
                sx={{
                  backgroundColor,
                  borderTopLeftRadius: index === 0 ? 10 : 0,
                  borderTopRightRadius: index === 0 ? 10 : 0,
                  borderBottomLeftRadius:
                    index === visibleItems.length - 1 ? 10 : 0,
                  borderBottomRightRadius:
                    index === visibleItems.length - 1 ? 10 : 0,
                }}
                onClick={() => handleItemClick(item.id)}
              >
                <ListItemAvatar sx={{lineHeight: "0px"}}>
                  <AvatarUrlComponent
                    telegramId={item.telegramId}
                    className="listing-avatar"
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={`${
                    userLanguage === "ru" ? item.cityFrom : item.cityFromEn
                  } – ${userLanguage === "ru" ? item.cityTo : item.cityToEn}`}
                  secondary={
                    listType === "orders" && userId !== item.authorId ? (
                      `${t("mainList.orderDateBy")} ${dayjs(item.dateBy).format(
                        "DD MMMM YYYY"
                      )}`
                    ) : listType === "trips" && userId !== item.authorId ? (
                      `${t("mainList.flightOn")}  ${dayjs(
                        item.date_departure
                      ).format("DD MMMM YYYY")}`
                    ) : userId === item.authorId ? (
                      <PostStatus state={item.state} />
                    ) : null
                  }
                />
                <NavigateNextIcon
                  sx={{
                    position: "absolute",
                    top: "50%",
                    right: "10px",
                    transform: "translateY(-50%)",
                  }}
                />
              </ListItemButton>
              {index !== visibleItems.length - 1 && (
                <Divider
                  sx={{
                    backgroundColor: dividerColor,
                    borderColor: dividerColor,
                    opacity: 0,
                  }}
                />
              )}
            </React.Fragment>
          ))}

          {totalPages > 1 && (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Pagination
                count={totalPages}
                color="primary"
                page={page}
                onChange={handleChangePage}
                sx={{ marginTop: "20px", marginBottom: "20px" }}
              />
            </Box>
          )}
        </>
      )}
      {selectedPost && (
        <DetailsModal
          listType={listType}
          postId={selectedPost}
          onClose={handleCloseModal}
        />
      )}
      {showSearchModal && (
        <SearchModal
          open={showSearchModal}
          onSearch={handleSearch}
          onClose={handleSearchCloseModal}
        />
      )}
    </>
  );
};

export default ListComponent;
