// EditPost.js

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";

import {
  Box,
  FormControl,
  TextField,
  Button,
  Stack,
  Typography,
} from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import dayjs from "dayjs";
import tripService from "../services/trip.service";
import orderService from "../services/order.service";
import LocationAutocomplete from "./ui/FormLocation";
import GeoNameService from "../services/geo-name.service";
import { WrapBox, StyledBox, StyledBoxNew } from "../styles";

const EditPost = ({ postType }) => {
  const { postId } = useParams();
  const { t } = useTranslation();
  const userLanguage = sessionStorage.getItem("user_language");
  const navigate = useNavigate();

  const [formData, setFormData] = useState(null);
  const [fromLocation, setFromLocation] = useState({
    place: "",
    place_en: "",
    countryId: "",
    cityId: "",
  });
  const [toLocation, setToLocation] = useState({
    place: "",
    place_en: "",
    countryId: "",
    cityId: "",
  });
  const [dateBy, setDateBy] = useState("new Date()");
  const [departureDate, setDepartureDate] = useState("new Date()");
  const [arrivalDate, setArrivalDate] = useState("new Date()");
  // ERROR STATES FOR FIELDS ANAD FORM
  const [formDescriptionError, setFormDescriptionError] = useState(false);
  const [formDescriptionBlurred, setFormDescriptionBlurred] = useState(false);
  const [dateByError, setDatebyError] = useState(false);
  const [dateDepartureError, setDateDepartureError] = useState(false);
  const [dateArrivalError, setDateArrivalError] = useState(false);
  const [formSubmittedError, setFormSubmittedError] = useState(false);
  const [fromLocationIsRequired, setFromLocationIsRequired] = useState(false);
  const [toLocationIsRequired, setToLocationIsRequired] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response;
        const fetchFunction =
          postType === "trip" ? tripService.get : orderService.get;
        response = await fetchFunction(postId);
        const responseDetails = response.data;
        setFormData(responseDetails);

        // if post is trip type > convert dates to iso format
        if (postType === "trip") {
          const departureDate = dayjs(responseDetails.date_departure);
          setDepartureDate(departureDate);

          const arrivalDate = dayjs(responseDetails.date_arrival);
          setArrivalDate(arrivalDate);
        }

        // if post is order type > convert dates to iso format
        if (postType === "order") {
          const dateBy = dayjs(responseDetails.dateBy);
          setDateBy(dateBy);
        }

        // Fetch country and city names for fromLocation and toLocation
        const [countryFrom, cityFrom, countryTo, cityTo] = await Promise.all([
          GeoNameService.getCountryById(responseDetails.countryFromId),
          GeoNameService.getCityById(responseDetails.cityFromId),
          GeoNameService.getCountryById(responseDetails.countryToId),
          GeoNameService.getCityById(responseDetails.cityToId),
        ]);

        // return country/city names depending on language
        const fromLocationString =
          userLanguage === "ru"
            ? `${cityFrom.data.city_name_ru}, ${countryFrom.data.country_name_ru}`
            : `${cityFrom.data.city_name_en}, ${countryFrom.data.country_name_en}`;

        // return country/city names depending on language
        const toLocationString =
          userLanguage === "ru"
            ? `${cityTo.data.city_name_ru}, ${countryTo.data.country_name_ru}`
            : `${cityTo.data.city_name_en}, ${countryTo.data.country_name_en}`;

        // set both location values
        setFromLocation({
          place: fromLocationString,
          place_en: fromLocationString,
          countryId: responseDetails.countryFromId,
          cityId: responseDetails.cityFromId,
        });
        setToLocation({
          place: toLocationString,
          place_en: toLocationString,
          countryId: responseDetails.countryToId,
          cityId: responseDetails.cityToId,
        });
      } catch (error) {
        console.error(`Error fetching ${postType} data:`, error);
      }
    };

    fetchData();
  }, [postId, postType]);

  // DESCRIPTION FIELD LOGIC //
  const handleDescriptionChange = (e) => {
    const { value } = e.target;
    setFormData({ ...formData, description: value });
    // run continious validation only if field was blurred with error before
    if (
      (formDescriptionBlurred && formDescriptionError) ||
      (formSubmittedError && formDescriptionError)
    ) {
      setFormDescriptionError(validateDescription(value));
    }
  };

  const handleDescriptionBlur = (e) => {
    const { value } = e.target;
    setFormData({ ...formData, description: value });
    // run validation only if more than 1 symbol
    if (value.length >= 1) {
      setFormDescriptionError(validateDescription(value));
    }
    // set state that the field has been touched
    setFormDescriptionBlurred(true);
  };

  const validateDescription = (value) => {
    if (value.length < 10) {
      return true;
    }
    return false;
  };
  // END OF DESCRIPTION FIELD LOGIC //

  // DATES FIELD LOGIC //
  const handleDateByChange = (value) => {
    // const { value } = e.target;
    const dateISO = dayjs(value).toISOString();
    setFormData({ ...formData, dateBy: dateISO });
    setDatebyError(validateDateIfToday(value));
  };

  const handleDateDepartureChange = (value) => {
    const dateISO = dayjs(value).toISOString();
    setFormData({ ...formData, date_departure: dateISO });
    setDateDepartureError(validateDateIfToday(value));
  };

  const handleDateArrivalChange = (value) => {
    const dateISO = dayjs(value).toISOString();
    setFormData({ ...formData, date_arrival: dateISO });
    setDateArrivalError(validateDateIfToday(value));
  };

  const validateDateIfToday = (value) => {
    const today = dayjs().startOf("day");
    const selectedDate = dayjs(value).startOf("day");

    if (selectedDate.isBefore(today)) {
      return true;
    }
    return false;
  };
  // END OF DATES FIELD LOGIC //

  // CLEAR ALL ERRORS //
  function clearErrors() {
    setFormDescriptionError(false);
    setFormDescriptionBlurred(false);
    setDatebyError(false);
    setDateDepartureError(false);
    setDateArrivalError(false);
    setFormSubmittedError(false);
    setFromLocationIsRequired(false);
    setToLocationIsRequired(false);
  }
  // END OF CLEAR ALL ERRORS

  // HANDLE DELETE
  const handleDelete = async () => {
    const confirmDelete = window.confirm(
      t("edit-trip.errorMessage.confirmDelete")
    );

    if (confirmDelete) {
      try {
        const deleteFunction =
          postType === "trip" ? tripService.delete : orderService.delete;
        await deleteFunction(postId);

        const redirectUrl =
          postType === "order" ? "/orders?myPosts=true" : "/trips?myPosts=true";

        handleNavigation();
      } catch (error) {
        console.error(`Error deleting ${postId}:`, error);
      }
    }
  };
  // END OF HANDLE DELETE

  // HANDLE NAVIGATION WHEN SAVING/DELETING //
  const handleNavigation = () => {
    const myOrdersUrl = "/app/orders?myPosts=true";
    const myTripsUrl = "/app/trips?myPosts=true";
    window.location.href = postType === "order" ? myOrdersUrl : myTripsUrl;
  };
  // END OF HANDLE NAVIGATION WHEN SAVING/DELETING //

  // HANDLE SUBMIT
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const updateFunction =
        postType === "trip" ? tripService.update : orderService.update;

      await updateFunction(postId, formData);
      handleNavigation();
    } catch (error) {
      console.error(`Error updating ${postId}:`, error);
    }
  };
  // END OF HANDLE DELETE

  // HANDLE CANCEL
  const handleCancel = () => {
    navigate(-1); // Navigates back one step in the history
  };
  // END OF HANDLE CANCEL

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <StyledBox>
        <Typography variant="h6">{t("editPost.title")}</Typography>
        <Typography variant="body2">{t("editPost.subtitle")}</Typography>
      </StyledBox>
      
      {formData && (
        <Box sx={{ display: "flex", flexDirection: "column", margin: "10px" }}>
          {/* from location selection */}
          <FormControl fullWidth sx={{ paddingBottom: "10px" }}>
            <LocationAutocomplete
              location={fromLocation}
              value={fromLocation}
              userLanguage={userLanguage}
              placeholder={t("new-post.form.toLocation.placeholder")}
              onChange={(location) =>
                setFormData((prevData) => ({
                  ...prevData,
                  countryFromId: location.country_id,
                  cityFromId: location.city_id,
                  place_ru: location.place,
                  place_en: location.place_en,
                }))
              }
              label={t("new-post.form.fromLocation.label")}
              onLocationSelect={(selectedLocation) => {}}
              error={fromLocationIsRequired}
              editMode={true}
            />
          </FormControl>
          {/* to location selection */}
          <FormControl fullWidth sx={{ paddingBottom: "10px" }}>
            <LocationAutocomplete
              location={toLocation}
              value={toLocation}
              userLanguage={userLanguage}
              placeholder={t("new-post.form.toLocation.placeholder")}
              onChange={(location) =>
                setFormData((prevData) => ({
                  ...prevData,
                  countryToId: location.country_id,
                  cityToId: location.city_id,
                  place_ru: location.place,
                  place_en: location.place_en,
                }))
              }
              label={t("new-post.form.toLocation.label")}
              onLocationSelect={(selectedLocation) => {}}
              error={toLocationIsRequired}
              editMode={true}
            />
            <Typography
              variant="body2"
              sx={{
                paddingTop: "4px",
                paddingBottom: "10px",
                paddingLeft: "5px",
                fontStyle: "italic",
              }}
            >
              {" "}
              * Вы не можете изменить место отпраления/прибытия, пожалуйста,
              создайте новое объявление.
            </Typography>
          </FormControl>
          <FormControl fullWidth sx={{ marginBottom: "10px" }}>
            {postType === "trip" && (
              <Stack direction="row" spacing={2}>
                <FormControl fullWidth sx={{ paddingBottom: "10px" }}>
                  <MobileDatePicker
                    locale={userLanguage}
                    name="dateDeparture"
                    label={t("edit-trip.form.dateDeparture.label")}
                    value={departureDate}
                    onChange={handleDateDepartureChange}
                    disablePast
                  />
                </FormControl>
                <FormControl fullWidth sx={{ paddingBottom: "10px" }}>
                  <MobileDatePicker
                    name="dateArrival"
                    label={t("edit-trip.form.dateArrival.label")}
                    value={arrivalDate}
                    onChange={handleDateArrivalChange}
                    disablePast
                  />
                </FormControl>
              </Stack>
            )}
            <TextField
              fullWidth
              value={formData.description}
              name="description"
              label={t("edit-trip.form.description.label")}
              placeholder={
                formData.type === "order"
                  ? t("new-post.form.description.order.placeholder")
                  : t("new-post.form.description.trip.placeholder")
              }
              onChange={handleDescriptionChange}
              multiline
              rows="5"
              inputProps={{ maxLength: 600 }}
            />
          </FormControl>
          {postType === "order" && (
            <FormControl fullWidth sx={{ marginBottom: "10px" }}>
              <MobileDatePicker
                name="dateBy"
                label={t("new-post.form.dateBy.label")}
                value={dateBy}
                onChange={handleDateByChange}
                disablePast
              />
            </FormControl>
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "15px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              sx={{ marginBottom: "15px" }}
            >
              {t("edit-trip.buttons.submit")}
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleCancel}
              sx={{ marginBottom: "15px" }}
            >
              {t("edit-trip.buttons.cancel")}
            </Button>
            <Button
              variant="text"
              color="error"
              onClick={handleDelete}
              size="small"
            >
              Delete {postType === "trip" ? "trip" : "order"}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default EditPost;
