// index.js

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import WebApp from "@twa-dev/sdk";
import ThemeWrapper from "./themeWrapper";
import { CssBaseline } from "@mui/material/";

WebApp.ready();

ReactDOM.createRoot(document.getElementById("root")).render(
  <BrowserRouter basename="/app/">
    <React.StrictMode>
      <ThemeWrapper>
        <CssBaseline />
        <App />
      </ThemeWrapper>
    </React.StrictMode>
  </BrowserRouter>
);
