//CreatePost.js

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import TripDataService from "../services/trip.service";
import OrderDataService from "../services/order.service";
import RequestPhoneNumber from "./requestPhoneNumber";
import LocationAutocomplete from "./ui/FormLocation";
import CreatePostModal from "./CreatePostDialog";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import {
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Stack,
  Button,
  TextField,
} from "@mui/material";
import { StyledBox } from "../styles";

const CreatePost = () => {
  const { t } = useTranslation();
  const userLanguage = sessionStorage.getItem("user_language");
  const userId = sessionStorage.getItem("user_id");
  const currentDate = new Date();
  const [isOrderCreated, setIsOrderCreated] = useState(false);
  const [isTripCreated, setIsTripCreated] = useState(false);
  const [inputsDisabled, setInputsDisabled] = useState(true);
  const [fromLocation, setFromLocation] = useState({
    place: "",
    place_en: "",
    countryId: "",
    cityId: "",
  });
  const [toLocation, setToLocation] = useState({
    place: "",
    place_en: "",
    countryId: "",
    cityId: "",
  });
  const [formData, setFormData] = useState({
    authorId: "",
    type: "",
    state: "",
    description: "",
    dateBy: "",
    date_departure: "",
    date_arrival: "",
    dateCreated: "",
    datePublished: "",
  });
  // ERROR STATES FOR FIELDS ANAD FORM
  const [formDescriptionError, setFormDescriptionError] = useState(false);
  const [formDescriptionBlurred, setFormDescriptionBlurred] = useState(false);
  const [dateByError, setDatebyError] = useState(false);
  const [dateDepartureError, setDateDepartureError] = useState(false);
  const [dateArrivalError, setDateArrivalError] = useState(false);
  const [formSubmittedError, setFormSubmittedError] = useState(false);
  const [fromLocationIsRequired, setFromLocationIsRequired] = useState(false);
  const [toLocationIsRequired, setToLocationIsRequired] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [postApiError, setPostApiError] = useState(false);

  // DESCRIPTION FIELD LOGIC //
  const handleDescriptionChange = (e) => {
    const { value } = e.target;
    setFormData({ ...formData, description: value });
    // run continious validation only if field was blurred with error before
    if (
      (formDescriptionBlurred && formDescriptionError) ||
      (formSubmittedError && formDescriptionError)
    ) {
      setFormDescriptionError(validateDescription(value));
    }
  };

  const handleDescriptionBlur = (e) => {
    const { value } = e.target;
    setFormData({ ...formData, description: value });
    // run validation only if more than 1 symbol
    if (value.length >= 1) {
      setFormDescriptionError(validateDescription(value));
    }
    // set state that the field has been touched
    setFormDescriptionBlurred(true);
  };

  const validateDescription = (value) => {
    if (value.length < 10) {
      return true;
    }
    return false;
  };
  // END OF DESCRIPTION FIELD LOGIC //

  // DATES FIELD LOGIC //
  const handleDateByChange = (value) => {
    // const { value } = e.target;
    const dateISO = dayjs(value).toISOString();
    setFormData({ ...formData, dateBy: dateISO });
    setDatebyError(validateDateIfToday(value));
  };

  const handleDateDepartureChange = (value) => {
    const dateISO = dayjs(value).toISOString();
    setFormData({ ...formData, date_departure: dateISO });
    setDateDepartureError(validateDateIfToday(value));
  };

  const handleDateArrivalChange = (value) => {
    const dateISO = dayjs(value).toISOString();
    setFormData({ ...formData, date_arrival: dateISO });
    setDateArrivalError(validateDateIfToday(value));
  };

  const validateDateIfToday = (value) => {
    const today = dayjs().startOf("day");
    const selectedDate = dayjs(value).startOf("day");

    if (selectedDate.isBefore(today)) {
      return true;
    }
    return false;
  };
  // END OF DATES FIELD LOGIC //

  // CLEAR ALL ERRORS //
  function clearErrors() {
    setFormDescriptionError(false);
    setFormDescriptionBlurred(false);
    setDatebyError(false);
    setDateDepartureError(false);
    setDateArrivalError(false);
    setFormSubmittedError(false);
    setFromLocationIsRequired(false);
    setToLocationIsRequired(false);
  }
  // END OF CLEAR ALL ERRORS

  const handleSubmit = async (e) => {
    e.preventDefault();

    const today = dayjs();
    const postType = formData.type;

    // CHECK ALL FIELDS BEFORE SUBMISSION
    let hasErrors = false;
    // check if toTocation is not empty on submit and set error state to true
    if (!formData.countryFromId) {
      setFromLocationIsRequired(true);
      hasErrors = true;
    } else {
      setFromLocationIsRequired(false);
    }

    // check from toTocation is not empty on submit and set error state to true
    if (!formData.countryToId) {
      setToLocationIsRequired(true);
      hasErrors = true;
    } else {
      setFromLocationIsRequired(false);
    }

    // check if description is not empty on submit and set error state to true
    if (!formData.description || validateDescription(formData.description)) {
      setFormDescriptionError(true);
      hasErrors = true;
    } else {
      setFormDescriptionError(false);
    }

    // [order specific] check if dateBy is not empty and set error state to true
    if (postType === "order") {
      if (!formData.dateBy || formData.dateBy < today) {
        setDatebyError(true);
        hasErrors = true;
      }
    } else {
      setDatebyError(false);
    }

    // [trip specific] check if dateDeparture and date Arrival are not empty and set error state to true
    if (postType === "trip") {
      if (!formData.date_departure || formData.date_departure < today) {
        setDateDepartureError(true);
        hasErrors = true;
      }
    } else {
      setDateDepartureError(false);
    }

    // [trip specific] check if dateDeparture and date Arrival are not empty and set error state to true
    if (postType === "trip") {
      if (!formData.date_arrival || formData.date_arrival < today) {
        setDateArrivalError(true);
        hasErrors = true;
      }
    } else {
      setDateArrivalError(false);
    }

    // FORM DATA AND POST API SUBMIT
    if (!hasErrors) {
      clearErrors();
      // console.log("NO ERRORS, CAPTAIN!");

      // form data depending on post type
      let data = {
        authorId: parseInt(userId),
        state: "1",
        dateCreated: dayjs(currentDate).toISOString(),
        datePublished: dayjs(currentDate).toISOString(),
        description: formData.description,
        countryFromId: formData.countryFromId,
        cityFromId: formData.cityFromId,
        countryToId: formData.countryToId,
        cityToId: formData.cityToId,
      };

      if (formData.type === "trip") {
        data = {
          ...data,
          date_departure: formData.date_departure,
          date_arrival: formData.date_arrival,
        };
      } else if (formData.type === "order") {
        data = {
          ...data,
          dateBy: formData.dateBy,
        };
      }

      const serviceMethod =
        formData.type === "trip"
          ? TripDataService.createTrip
          : OrderDataService.createOrder;

      console.log("data to post", data);
      serviceMethod(data)
        .then((response) => {
          if (response.data.success) {
            if (formData.type === "trip") {
              setIsTripCreated(true);
            } else if (formData.type === "order") {
              setIsOrderCreated(true);
            }
          } else {
            setPostApiError("error");
            setIsTripCreated(false);
            setIsOrderCreated(false);
          }
        })
        .catch((postApiError) => {
          console.log(postApiError);
          setPostApiError("error");
          setIsTripCreated(false);
          setIsOrderCreated(false);
        })
        .finally(() => {
          setIsModalOpen(true);
        });
    }
    setFormSubmittedError(hasErrors);
    // CHECK ALL FIELDS BEFORE SUBMISSION
  };

  console.log("Form Data", formData);

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {!formData.type && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              alt={"Create a New Post"}
              src={"./images/newPost.png"}
              style={{ marginTop: "10px", width: "30%" }}
            />
          </Box>
        )}
        <Box sx={{ padding: "10px" }}>
          <RequestPhoneNumber
            inputsDisabled={inputsDisabled}
            setInputsDisabled={setInputsDisabled}
            sx={{ marginTop: "5px", marginBottom: "10px" }}
          />
        </Box>
        <StyledBox>
          <Typography variant="h6">{t("new-post.title")}</Typography>
          <Typography variant="body2">{t("new-post.subtitle")}</Typography>
        </StyledBox>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{ padding: "10px" }}
        >
          {/* select type of new post dropdown */}
          <FormControl fullWidth sx={{ marginBottom: "10px" }}>
            <InputLabel id="postTypeLabel" htmlFor="type">
              {t("new-post.form.type.label")}
            </InputLabel>
            <Select
             // defaultOpen={inputsDisabled} // if phone and username are not provided, it still keep opens
              labelId="postTypeLabel"
              id="type"
              name="type"
              label={t("new-post.form.type.label")}
              value={formData.type}
              disabled={inputsDisabled}
              onChange={(event) =>
                setFormData({
                  ...formData,
                  [event.target.name]: event.target.value,
                })
              }
              required
              variant="outlined"
            >
              <MenuItem value="order">{t("new-post.form.type.order")}</MenuItem>
              <MenuItem value="trip">{t("new-post.form.type.trip")}</MenuItem>
            </Select>
          </FormControl>
          {/* display other fields only when type is selected */}
          {formData.type && (
            <>
              {/* from location selection */}
              <FormControl fullWidth sx={{ paddingBottom: "10px" }}>
                <LocationAutocomplete
                  location={fromLocation}
                  userLanguage={userLanguage}
                  placeholder={t("new-post.form.toLocation.placeholder")}
                  onChange={(location) =>
                    setFormData((prevData) => ({
                      ...prevData,
                      countryFromId: location.country_id,
                      cityFromId: location.city_id,
                      place_ru: location.place,
                      place_en: location.place_en,
                    }))
                  }
                  label={t("new-post.form.fromLocation.label")}
                  onLocationSelect={(selectedLocation) => {}}
                  error={fromLocationIsRequired}
                />
              </FormControl>
              {/* to location selection */}
              <FormControl fullWidth sx={{ paddingBottom: "10px" }}>
                <LocationAutocomplete
                  location={toLocation}
                  userLanguage={userLanguage}
                  placeholder={t("new-post.form.toLocation.placeholder")}
                  onChange={(location) =>
                    setFormData((prevData) => ({
                      ...prevData,
                      countryToId: location.country_id,
                      cityToId: location.city_id,
                      place_ru: location.place,
                      place_en: location.place_en,
                    }))
                  }
                  label={t("new-post.form.toLocation.label")}
                  onLocationSelect={(selectedLocation) => {}}
                  error={toLocationIsRequired}
                />
              </FormControl>
              {formData.type === "trip" && (
                <>
                  <Stack direction="row" spacing={2}>
                    <FormControl fullWidth sx={{ paddingBottom: "10px" }}>
                      <MobileDatePicker
                        name="dateDeparture"
                        label={t("new-post.form.dateDeparture.label")}
                        onChange={handleDateDepartureChange}
                        error={dateDepartureError}
                        disablePost
                        slotProps={{
                          textField: {
                            error: !!dateDepartureError,
                            helperText: dateDepartureError
                              ? t("new-post.errorMessage.dateDepartureEmpty")
                              : "",
                          },
                        }}
                        disablePast
                      />
                    </FormControl>
                    <FormControl fullWidth sx={{ paddingBottom: "10px" }}>
                      <MobileDatePicker
                        name="dateArrival"
                        label={t("new-post.form.dateArrival.label")}
                        onChange={handleDateArrivalChange}
                        error={dateArrivalError}
                        disablePost
                        slotProps={{
                          textField: {
                            error: !!dateArrivalError,
                            helperText: dateArrivalError
                              ? t("new-post.errorMessage.dateArrivalEmpty")
                              : "",
                          },
                        }}
                        disablePast
                      />
                    </FormControl>
                  </Stack>
                </>
              )}
              <FormControl fullWidth sx={{ paddingBottom: "10px" }}>
                <TextField
                  fullWidth
                  // value="description"
                  name="description"
                  onChange={handleDescriptionChange}
                  label={
                    formData.type === "order"
                      ? t("new-post.form.description.order.label")
                      : t("new-post.form.description.trip.label")
                  }
                  placeholder={
                    formData.type === "order"
                      ? t("new-post.form.description.order.placeholder")
                      : t("new-post.form.description.trip.placeholder")
                  }
                  multiline
                  rows="4"
                  inputProps={{ maxLength: 600 }}
                  error={formDescriptionError}
                  helperText={
                    formDescriptionError
                      ? t("new-post.errorMessage.descriptionEmpty")
                      : undefined
                  }
                  onBlur={handleDescriptionBlur}
                />
              </FormControl>
              {formData.type === "order" && (
                <FormControl fullWidth sx={{ paddingBottom: "10px" }}>
                  <MobileDatePicker
                    name="dateBy"
                    label={t("new-post.form.dateBy.label")}
                    onChange={handleDateByChange}
                    error={dateByError}
                    disablePast
                    slotProps={{
                      textField: {
                        error: !!dateByError,
                        helperText: dateByError
                          ? t("new-post.errorMessage.dateArrivalEmpty")
                          : "",
                      },
                    }}
                  />
                </FormControl>
              )}
            </>
          )}
          {formData.type && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingTop: "12px",
                paddingBottom: "20px",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                type="submit"
                size="large"
                noValidate
              >
                {t("new-post.form.buttons.postButton")}
              </Button>
            </Box>
          )}
        </Box>
      </Box>
      <CreatePostModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        isTripCreated={isTripCreated}
        isOrderCreated={isOrderCreated}
        postError={postApiError}
        t={t}
        postType={formData.postType}
      />
    </>
  );
};

export default CreatePost;
