//user.service.js
import axios from 'axios'

class UserService {
  getUserById (id) {
    return axios.get(`/api/user/${id}`)
  }

  get(id) {
    return axios.get(`/api/user/${id}`);
  }
}

const userService = new UserService();

export default userService;
