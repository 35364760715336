// EmptyState.js
import React from "react";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import {
  Typography,
  Link,
  Button,
  Box,
  Alert,
  AlertTitle,
} from "@mui/material";

const EmptyStateComponent = ({ listType, showMyItems, showSearchChip }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const getEmptyStateContent = () => {
    // Search empty state
    if (!showMyItems && showSearchChip) {
      return (
        <>
          <img
            src={"./images/emptyStateSearch.png"}
            alt={listType === "trips" ? "No Trips" : "No Orders"}
            style={{ marginTop: "10px", width: "40%" }}
          />
          <Typography variant="h6">
            {listType === "trips"
              ? t("mainList.emptyState.searchPosts.trips.title")
              : t("mainList.emptyState.searchPosts.orders.title")}
          </Typography>
          <Typography variant="body1" sx={{ textAlign: "center" }}>
            {listType === "trips"
              ? t("mainList.emptyState.searchPosts.trips.message")
              : t("mainList.emptyState.searchPosts.orders.message")}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              window.location.href = "/app/createpost";
            }}
            sx={{ mt: 2 }}
          >
            {listType === "trips"
              ? t("mainList.emptyState.createTripButton")
              : t("mainList.emptyState.createOrderButton")}
          </Button>
          {/* <Alert severity="info">
                <AlertTitle>{t("mainList.featureAnnounce")}</AlertTitle>
                <Typography variant="body6">
                &nbsp;
                {t("mainList.featureAnnounce")}
                <Link href="https://t.me/whoflies_official" underline="none" sx={{ ml: 1 }}>
                  {t("mainList.featureAnnounceLink")}
                </Link>
              </Typography>
            </Alert> */}
        </>
      );

      // My trips/orders empty state
    } else if (showMyItems) {
      return (
        <>
          <img
            src={
              listType === "trips"
                ? "./images/emptyStateTrips.png"
                : "./images/emptyStateOrders.png"
            }
            alt={listType === "trips" ? "No Trips" : "No Orders"}
            style={{ marginTop: "10px", width: "50%" }}
          />
          <Typography variant="h6">
            {listType === "trips"
              ? t("mainList.emptyState.myPosts.trips.title")
              : t("mainList.emptyState.myPosts.orders.title")}
          </Typography>
          <Typography variant="body1" sx={{ textAlign: "center" }}>
            {listType === "trips"
              ? t("mainList.emptyState.myPosts.trips.message")
              : t("mainList.emptyState.myPosts.orders.message")}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              window.location.href = "/app/createpost";
            }}
            sx={{ mt: 2 }}
          >
            {listType === "trips"
             ? t("mainList.emptyState.createTripButton")
             : t("mainList.emptyState.createOrderButton")}
          </Button>
        </>
      );
    } else {
      // Handle other conditions if needed
      return null;
    }
  };

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      {getEmptyStateContent()}
    </Box>
  );
};

export default EmptyStateComponent;
