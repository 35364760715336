// TabSwitcher.js

import * as React from "react";
import { useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import ListComponent from "./ListComponent";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function TabSwitcher(props) {
  const { t } = useTranslation();
  const { value, index, listType, myPosts, authorId, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div sx={{ margin: "10px" }}>
          <ListComponent listType={listType} myPosts={props.myPosts}/>
        </div>
      )}
    </div>
  );
}

TabSwitcher.propTypes = {
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  listType: PropTypes.string.isRequired,
  myPosts: PropTypes.bool,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ListWrapper({ initialTab = 0, myPosts }) {
  const { t } = useTranslation();
  const [value, setValue] = React.useState(
    initialTab === 0 || initialTab === 1 ? initialTab : 0
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const navigate = useNavigate();
  const [deepLinkPostId, setDeepLinkPostId] = React.useState(null);

  // ACTIVATE TAB FROM TELEGRAM DEEP LINKING URL AND OPEN MODAL BY DIRECT POST URL
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const startAppParam = urlParams.get("tgWebAppStartParam");

    if (startAppParam) {
      const { category, deepLinkPostId } = parseStartAppParam(startAppParam);
      const routeCategory = category === "orderId" ? "orders" : "trips";
      setDeepLinkPostId(deepLinkPostId);

      // Use setValue to activate the correct tab
      if (category === "orderId") {
        setValue(0);
      } else if (category === "tripId") {
        setValue(1);
      } else {
        console.error("Invalid category:", category);
      }

      navigate(`/${routeCategory}/${deepLinkPostId}`);
    }
  }, [navigate]);

  // parse url parameters for telegram deep link
  function parseStartAppParam(startAppParam) {
    const [category, oDeepLinkPostId] = startAppParam.split("-");
    const deepLinkPostId = isNaN(Number(oDeepLinkPostId))
      ? oDeepLinkPostId
      : Number(oDeepLinkPostId);
    return { category, deepLinkPostId };
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ bgcolor: "#ccc", margin: "10px", borderRadius: "10px" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          centered
          TabIndicatorProps={{ style: { background: "none" } }}
        >
          <Tab
            label={
              <div>
                <span
                  style={{
                    display: "block",
                    color:
                      value === 0
                        ? "var(--tg-theme-button-text-color)"
                        : "var(--tg-theme-subtitle-text-color)",
                    fontSize: "1.1em",
                  }}
                >
                  {t("mainList.listSwitcher.ordersTitle")}
                </span>
                <span
                  style={{
                    display: "block",
                    color:
                      value === 0
                        ? "var(--tg-theme-button-text-color)"
                        : "var(--tg-theme-subtitle-text-color)",
                    fontSize: "0.7em",
                    paddingTop: "2px",
                  }}
                >
                  {t("mainList.listSwitcher.ordersSubtitle")}
                </span>
              </div>
            }
            {...a11yProps(0)}
            sx={{
              width: "50%",
              maxWidth: "none",
              borderBottom: "none",
              outline: "none",
              backgroundColor:
                value === 0 ? "var(--tg-theme-button-color)" : "transparent",
              borderRadius: value === 0 ? "10px" : "10px",
            }}
          />
          <Tab
            label={
              <div>
                <span
                  style={{
                    display: "block",
                    color:
                      value === 1
                        ? "var(--tg-theme-button-text-color)"
                        : "var(--tg-theme-subtitle-text-color)",
                    fontSize: "1.1em",
                  }}
                >
                  {t("mainList.listSwitcher.tripsTitle")}
                </span>
                <span
                  style={{
                    display: "block",
                    color:
                      value === 1
                        ? "var(--tg-theme-button-text-color)"
                        : "var(--tg-theme-subtitle-text-color)",
                    fontSize: "0.7em",
                    paddingTop: "2px",
                  }}
                >
                  {t("mainList.listSwitcher.tripsSubtitle")}
                </span>
              </div>
            }
            {...a11yProps(1)}
            sx={{
              width: "50%",
              maxWidth: "none",
              borderBottom: "none",
              backgroundColor:
                value === 1 ? "var(--tg-theme-button-color)" : "transparent",
              borderRadius: value === 1 ? "10px" : "10px",
            }}
          />
        </Tabs>
      </Box>
      <Box sx={{ marginLeft: "10px", marginRight: "10px" }}>
        <TabSwitcher value={value} index={0} listType="orders" myPosts={myPosts}></TabSwitcher>
        <TabSwitcher value={value} index={1} listType="trips" myPosts={myPosts}></TabSwitcher>
      </Box>
    </Box>
  );
}
