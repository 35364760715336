import React, { useState, useEffect } from "react";
import UserService from "../services/user.service";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";
import { Box, Button, Typography } from "@mui/material";

function RequestPhoneNumber({ inputsDisabled, setInputsDisabled }) {
  const [userData, setUserData] = useState(null);
  const [isValidating, setIsValidating] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const userId = sessionStorage.getItem("user_id");
  const [showContent, setShowContent] = useState(true);
  const [validationStatus, setValidationStatus] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await UserService.get(userId);
        setUserData(response.data);
        if (
          (response.data.telegramUsername &&
            response.data.telegramUsername !== "0") ||
          (response.data.phoneNumber && response.data.phoneNumber !== "0")
        ) {
          setInputsDisabled(false);
        }
      } catch (error) {
        console.error("Error fetching user data: ", error);
      }
    };
    fetchUserData();
  }, [userId, setInputsDisabled]);

  const handleButtonClick = () => {
    if (!isValidating) {
      window.Telegram.WebApp.requestContact((userSharedPhoneNumber) => {
        if (userSharedPhoneNumber) {
          setShowContent(false);
          setPhoneNumber(userSharedPhoneNumber);
          setIsValidating(true);
          setTimeout(() => {
            validatePhoneNumber(userSharedPhoneNumber);
          }, 3000); // 3-second delay before validating
        }
      });
    }
  };

  const validatePhoneNumber = async (userSharedPhoneNumber) => {
    try {
      const response = await UserService.get(userId);
      if (response.data.phoneNumber && response.data.phoneNumber !== "0") {
        setInputsDisabled(false);
        setIsValidating(false);
        setValidationStatus(t("requestPhoneNumber.validationSuccessful"));
      } else {
        setInputsDisabled(true);
        setIsValidating(false);
        setValidationStatus(t("requestPhoneNumber.validationFailed"));
      }
    } catch (error) {
      setInputsDisabled(true);
      setIsValidating(false);
      setValidationStatus(t("requestPhoneNumber.validationFailed"));
      console.error("Error fetching user data: ", error);
    }
  };

  const handleAlertClose = () => {
    setShowContent(false);
    setValidationStatus("");
  };

  return (
    <Box>
      {showContent &&
        (!userData ||
          ((userData.phoneNumber === undefined ||
            userData.phoneNumber === "0" ||
            userData.phoneNumber === "") &&
            (userData.telegramUsername === undefined ||
              userData.telegramUsername === "0" ||
              userData.telegramUsername === ""))) && (
          <Alert severity="error">
            <Typography variant="subtitle2" align="justify">{t("requestPhoneNumber.shareNumberMessage")}</Typography>
            <br />
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                onClick={handleButtonClick}
                size="small"
              >
                {t("requestPhoneNumber.shareNumberButton")}
              </Button>
            </Box>
          </Alert>
        )}
      {isValidating && (
        <Alert severity="warning">
          {t("requestPhoneNumber.shareNumberValidating")}
        </Alert>
      )}
      {validationStatus && (
        <Alert severity="success" onClose={handleAlertClose}>
          <span>{validationStatus}</span>
          <Button
            data-dismiss="alert"
            aria-label="Close"
            onClick={handleAlertClose}
          ></Button>
        </Alert>
      )}
    </Box>
  );
}

export default RequestPhoneNumber;
